<section>
    <label class="flex-column mt-2 h-100">
        <span class="label mb-1">{{ label }}</span>
        <ng-select
            [(ngModel)]="selected"
            (ngModelChange)="onChange($event)"
            [items]="items"
            [loading]="!items"
            [multiple]="multiple"
            [searchFn]="searchFn"
            [disabled]="disabled"
            [groupBy]="groupByFn"
            [groupValue]="groupValFn"
            placeholder="{{ placeholder }}"
            appendTo="{{ appendTo }}"
            bindValue="{{ bindValue }}"
        >
            <ng-template
                *ngIf="multiple"
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
                class="ng-select-container"
            >
                <div class="ng-value flex gap-0" *ngFor="let item of items | slice: 0 : 6">
                    <span class="ng-value-label">{{ item['_displayValue'] }}</span>
                    <span class="material-icons ng-value-icon right" (click)="clear(item)" aria-hidden="true"
                        >close</span
                    >
                </div>

                <div class="ng-value" *ngIf="items.length > 6">
                    <span class="ng-value-label">og {{ items.length - 6 }} til</span>
                </div>
            </ng-template>

            <ng-template
                *ngIf="!multiple"
                ng-label-tmp
                let-item="item"
                let-clear="clear"
                class="ng-select-single-container"
            >
                <div class="ng-value">
                    <span class="ng-value-label">{{ item['_displayValue'] }}</span>
                </div>
            </ng-template>

            <ng-template ng-optgroup-tmp let-item="item">
                <span style="vertical-align: middle">{{ item }}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <i *ngIf="!!icon" class="material-icons">{{ icon }}</i>
                {{ item['_displayValue'] }}
            </ng-template>
        </ng-select>
        <span class="color-bad mt-1 text-small" *ngIf="showError"
            ><i class="material-icons">error</i>{{ errorMsg }}</span
        >
    </label>
</section>
