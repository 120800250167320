@if (task) {
    @if (task.completed) {
        <div class="task-tile completed flex align-center">
            <i class="checkmark material-icons">check_circle</i>
            <span> {{ task.completeLabel }} </span>
        </div>
    } @else {
        <button (click)="onActionClick()" class="task-tile unstyled border rounded w-100 flex align-center">
            <span class="task-icon flex align-center justify-center" aria-hidden="true">
                <i class="material-icons-outlined color-c2a">{{ task.icon }}</i>
            </span>

            <span> {{ task.label }}</span>

            <i class="material-icons ml-auto" aria-hidden="true"> chevron_right </i>
        </button>
    }
}
