import { Injectable } from '@angular/core';
import { UniHttp } from '@uni-framework/core/http/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface OpenAIResponse {
    id: string;
    choices: OpenAIChoice[];
}

export interface OpenAIChoice {
    index: number;
    finish_reason: string;
    message: OpenAIMessage;
}

export interface OpenAIMessage {
    content: string;
}

@Injectable({ providedIn: 'root' })
export class AIGenerateService {
    constructor(protected http: UniHttp) {}

    generate(prompt: string, temperature = 0, topPercentage = 99): Observable<OpenAIResponse> {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`ai-generate?action=generate-text`)
            .withBody({ Prompt: prompt, Temperature: temperature, TopPercentage: topPercentage })
            .send({}, null, true)
            .pipe(
                map((res) => {
                    const result = res.body as OpenAIResponse;
                    if (result && result?.choices && result?.choices?.length > 0) {
                        const firstResult = result.choices[0];
                        firstResult.message.content = this.cleanupResponse(firstResult.message.content);
                    }

                    return result;
                }),
            );
    }

    checkIfHasAI() {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`ai-generate?action=check-has-ai`)
            .send()
            .pipe(map((res) => res.body));
    }

    private cleanupResponse(text: string): string {
        if (!text) return text;
        if (text.startsWith('.')) {
            text = text.substring(1);
        }
        if (text.startsWith('\n\n')) {
            text = text.substring(2);
        }
        if (text.startsWith('"') && text.endsWith('"')) {
            text = text.substring(1, text.length - 2);
        }
        return text.trim();
    }
}
