<div class="editor-container">
    <div class="main-container flex gap-1">
        <div class="flex flex-column flex-1">
            <div class="tb">
                <button
                    (click)="toggleStyle('bold')"
                    title="Fet skrift"
                    class="btn-bold"
                    onmousedown="event.preventDefault()"
                >
                    B
                </button>
                <button
                    (click)="toggleStyle('italic')"
                    title="Skråstilt skrift"
                    class="btn-italic"
                    onmousedown="event.preventDefault()"
                >
                    I
                </button>
                <button
                    (click)="toggleStyle('underline')"
                    title="Underlinje skrift"
                    class="btn-underline"
                    onmousedown="event.preventDefault()"
                >
                    U
                </button>
                <button [attr.aria-busy]="saving" (click)="onSaveClick()" [disabled]="saving" title="Lagre endringer">
                    <i class="material-icons-outlined">save</i>
                </button>
                <button (click)="deleteEditorContent()" title="Tøm kommentar">
                    <uni-icon [icon]="icon" [svgSize]="20"></uni-icon>
                </button>
                <button
                    (click)="openTemplateImport()"
                    class="tb-pull-right"
                    title="Legg til egne tekstmaler til kommentaren"
                    onmousedown="event.preventDefault()"
                >
                    <i class="material-icons-outlined mr-1">article</i>
                    Tekstmaler
                </button>
                @if (hasAI) {
                    <button
                        [attr.aria-busy]="generating"
                        (click)="openTextSettings(true)"
                        title="La kunstig intelligens foreslå kommentaren"
                        onmousedown="event.preventDefault()"
                    >
                        <i class="material-icons-outlined color-good mr-1">auto_fix_high</i>
                        KI-forslag
                    </button>
                }
            </div>

            <div
                id="editor"
                class="border rounded flex-1 custom-scrollbar"
                #editor
                (input)="checkForChanges()"
                contenteditable="true"
                [attr.aria-busy]="loading"
            ></div>
        </div>

        <div [attr.aria-busy]="loadingKpi" class="kpi flex-column gap-1 rounded">
            <strong class="text-small flex-0-auto text-center">Nøkkeltall {{ kpiMonths?.label }} {{ id }}</strong>
            <div class="kpi-list flex-1 rounded text-small custom-scrollbar">
                <div
                    (click)="tryUseKpi(kpi)"
                    onmousedown="event.preventDefault()"
                    title="{{ kpi.title }}"
                    class="kpi-item flex-column"
                    *ngFor="let kpi of kpiList"
                >
                    <span class="color-subdued">{{ kpi.label }}</span>
                    <strong>
                        {{ kpi.value | uninumberformat: 'rounded' }}
                        <i *ngIf="kpi.icon" class="material-icons pull-right" [class]="kpi.trendColor">{{
                            kpi.icon
                        }}</i>
                    </strong>
                </div>
            </div>
        </div>
    </div>
</div>
