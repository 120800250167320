import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { UniModalService } from '@uni-framework/uni-modal';
import { AuthService } from '@app/authService';
import { SupportChat } from '../../support-chat/support-chat';
import { Subscription } from 'rxjs';
import { ContractType, ElsaCustomerType, ElsaUserLicenseType } from '@app/models';
import { GiveSupportAccessModal } from './give-support-access-modal';

@Component({
    selector: 'uni-tabstrip-help',
    template: `
        <uni-icon #trigger [icon]="'help'" matTooltip="Lisensinformasjon og support"></uni-icon>

        <dropdown-menu [trigger]="trigger">
            <ng-template>
                @if ((isUeEnvironment && !isAgencyStandardUser) || isKraftEnvironment) {
                    <a class="dropdown-menu-item" [href]="uniSupportUrl" target="_blank"> Opprett supportsak </a>

                    @if (!isKraftEnvironment) {
                        <a
                            class="dropdown-menu-item"
                            href="https://unimicro.atlassian.net/servicedesk/customer/portal/3/group/7/create/106"
                            target="_blank"
                        >
                            Telefonsupport (hastesaker)
                        </a>
                    }

                    <a
                        class="dropdown-menu-item"
                        href="https://unimicro.atlassian.net/servicedesk/customer/user/requests?status=open"
                        target="_blank"
                    >
                        Mine supportsaker
                    </a>
                }

                @if (isUeEnvironment) {
                    <a class="dropdown-menu-item" href="https://get.teamviewer.com/atrdwfq" target="_blank">
                        Teamviewer nedlasting
                    </a>
                }

                @if (isEikaEnvironment) {
                    @if (showEikaBureauSupport()) {
                        <a
                            class="dropdown-menu-item"
                            href="https://unimicro.atlassian.net/servicedesk/customer/portal/3/create/24"
                            target="_blank"
                        >
                            Opprett supportsak
                        </a>

                        <a
                            class="dropdown-menu-item"
                            href="https://unimicro.atlassian.net/servicedesk/customer/portal/3/group/7/create/106"
                            target="_blank"
                        >
                            Telefonsupport (hastesaker)
                        </a>
                    } @else {
                        <a
                            class="dropdown-menu-item"
                            href="mailto:eikaregnskapsupport@eika.no?subject=Supporthenvendelse {{ orgNumber || '' }}"
                        >
                            Opprett supportsak
                        </a>
                    }
                }

                <a class="dropdown-menu-item" routerLink="/about/system"> Systeminformasjon </a>

                <a class="dropdown-menu-item" routerLink="/license-info"> Lisensinformasjon </a>

                @if (isSB1Environment) {
                    @if (supportChatEnabled && chatComponent?.createSupportCase) {
                        <a class="dropdown-menu-item" (click)="chatComponent.createSupportCase()">
                            Opprett supportsak
                        </a>
                    }

                    @if (supportPageUrl && !supportChatEnabled) {
                        <a class="dropdown-menu-item" [href]="supportPageUrl" target="_blank"> Opprett supportsak </a>
                    }
                }

                @if (isSB1Environment || isDNBEnvironment) {
                    <a class="dropdown-menu-item" (click)="openGiveSupportAccessModal()"> Gi support tilgang </a>
                }

                <a
                    class="dropdown-menu-item"
                    href="https://trello.com/b/5Bddmnlk/dnb-regnskap-sparebank-1-regnskap-uni-economy-produktutvikling-og-ønsker"
                    target="_blank"
                >
                    Ønsker produktutvikling
                </a>

                @if (helpdeskUrl) {
                    <a class="dropdown-menu-item" [href]="helpdeskUrl" target="_blank"> Hjelp </a>
                }
            </ng-template>
        </dropdown-menu>

        @if (supportChatEnabled) {
            <support-chat />
        }
    `,
    styleUrls: ['./help.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniTabstripHelp {
    @ViewChild(SupportChat) chatComponent: SupportChat;

    isUeEnvironment = theme.theme === THEMES.UE;
    isSB1Environment = theme.theme === THEMES.SR;
    isDNBEnvironment = theme.theme === THEMES.EXT02;
    isEikaEnvironment = theme.theme === THEMES.EIKA;
    isKraftEnvironment = location.host === 'sk.unimicro.no';

    helpdeskUrl: string;
    supportPageUrl: string;
    isAgencyStandardUser: boolean;
    uniSupportUrl = 'https://unimicro.atlassian.net/servicedesk/customer/portal/3/create/24';
    supportChatEnabled: boolean;

    publicSettingsSubscription: Subscription;
    authenticationSubscription: Subscription;

    orgNumber: string;

    constructor(
        private modalService: UniModalService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {
        this.publicSettingsSubscription = this.authService.publicSettingsLoaded$.subscribe((loaded) => {
            if (loaded) {
                this.checkSupportChatEnabled();

                // every else-if can be removed when we're sure every environment has set HelpDeskUrl in Elsa
                if (this.authService.publicSettings?.HelpDeskUrl) {
                    this.helpdeskUrl = this.authService.publicSettings.HelpDeskUrl;
                } else if (this.isUeEnvironment) {
                    this.helpdeskUrl = 'https://help.unieconomy.no';
                } else if (this.isSB1Environment) {
                    this.helpdeskUrl =
                        'https://www.sparebank1.no/nb/sr-bank/bedrift/produkter/bank-regnskap/hjelp.html';
                } else if (this.isDNBEnvironment) {
                    this.helpdeskUrl = 'https://hjelp.dnbregnskap.dnb.no/no/';
                }

                if (this.isSB1Environment && this.authService.publicSettings?.SupportPageUrl) {
                    this.supportPageUrl = this.authService.publicSettings.SupportPageUrl;
                }
                this.cdr.markForCheck();
            }
        });

        this.authenticationSubscription = this.authService.authentication$?.subscribe((authDetails) => {
            this.isAgencyStandardUser =
                authDetails?.user?.License?.CustomerInfo?.CustomerType === 5 &&
                authDetails?.user?.License?.UserType?.TypeID === 0;

            if (theme.theme === THEMES.UE) {
                const contractType = authDetails?.user?.License?.ContractType?.TypeID;
                const isStandardCustomer = authDetails?.user?.License?.CustomerInfo?.CustomerType === 0;

                if (contractType === ContractType.Level4) {
                    // V3 Lønn
                    this.uniSupportUrl = 'https://unimicro.atlassian.net/servicedesk/customer/portal/19/create/203';
                } else if (
                    isStandardCustomer &&
                    (contractType === ContractType.Mini || contractType === ContractType.Plus)
                ) {
                    this.uniSupportUrl = 'https://unimicro.atlassian.net/servicedesk/customer/portal/20/create/204';
                } else {
                    this.uniSupportUrl = 'https://unimicro.atlassian.net/servicedesk/customer/portal/3/create/24';
                }
            }

            this.orgNumber = authDetails.activeCompany?.OrganizationNumber;

            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
        this.publicSettingsSubscription?.unsubscribe();
        this.authenticationSubscription?.unsubscribe();
    }

    openGiveSupportAccessModal() {
        this.modalService.open(GiveSupportAccessModal);
    }

    showEikaBureauSupport() {
        const license = this.authService.currentUser?.License;
        return (
            theme.theme === THEMES.EIKA &&
            (license?.CustomerInfo?.IsRoamingUser || license?.UserType?.TypeID === ElsaUserLicenseType.Accountant)
        );
    }

    checkSupportChatEnabled() {
        if (!this.authService.publicSettings?.SupportChatEnabled) {
            this.supportChatEnabled = false;
            return;
        }

        if (!this.isSB1Environment) {
            this.supportChatEnabled = true;
            return;
        }

        if (this.authService.publicSettings?.SupportChatEnabledForBureau) {
            this.supportChatEnabled = true;
        } else {
            this.supportChatEnabled = this.authService.currentUser.License?.CustomerInfo?.CustomerType !== 5; //not bureau
        }
    }
}
