import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'startup-task',
    templateUrl: './task-template.html',
    styleUrls: ['./task-template.sass'],
})
export class TaskTemplate {
    @Input() task: any;
    @Output() tileClicked = new EventEmitter();

    onActionClick() {
        if (!this.task.completed) {
            this.tileClicked.emit();
        }
    }
}
