import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable, forkJoin, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DashboardDataService } from '../../../dashboard-data.service';

import { AuthService } from '@app/authService';
import { ApprovalStatus, Task, Approval, FinancialDeadline } from '@uni-entities';
import { FeaturePermissionService } from '@app/featurePermissionService';
import { UniModalService } from '@uni-framework/uni-modal';
import { TaskModal } from '@app/components/common/modals/task-modal/task-modal';

import PerfectScrollbar from 'perfect-scrollbar';
import { rigDate } from '@app/components/common/utils/rig-date';
import { theme, THEMES } from 'src/themes/theme';
import { CustomerInvoiceReminderService } from '@app/services/sales/customerInvoiceReminderService';
import { ApprovalService } from '@app/services/assignments/approvalService';

@Component({
    selector: 'reminder-widget',
    templateUrl: './reminder-widget.html',
    styleUrls: ['./reminder-widget.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReminderWidget {
    options: { showPublicDueDates: boolean };

    dataSubscription: Subscription;
    scrollbar: PerfectScrollbar;

    tasks: Task[] = [];
    invoiceApprovals: Approval[];
    timesheetApprovals: Approval[];
    publicDueDates: FinancialDeadline[];

    counters: {
        invoicesReadyForPayment?: number;
        invoicesReadyForReminder?: number;
        paymentWithoutMatch?: number;
        inbox?: number;
    } = {};

    constructor(
        private modalService: UniModalService,
        private featurePermissionService: FeaturePermissionService,
        private authService: AuthService,
        private dataService: DashboardDataService,
        private approvalService: ApprovalService,
        private cdr: ChangeDetectorRef,
        private reminderService: CustomerInvoiceReminderService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.loadData();
    }

    ngOnDestroy() {
        this.scrollbar?.destroy();
        this.dataSubscription?.unsubscribe();
    }

    private loadData() {
        const requests: Observable<any>[] = [this.getApprovals(), this.getTasks(), this.getCounters()];

        if (this.options?.showPublicDueDates) {
            requests.push(this.getPublicDueDates());
        }

        this.dataSubscription = forkJoin(requests).subscribe({
            next: ([approvals, tasks, counters, publiceDuedates]) => {
                this.invoiceApprovals = approvals.invoices;
                this.timesheetApprovals = approvals.timesheets;

                this.tasks = tasks;
                this.counters = counters;
                this.publicDueDates = publiceDuedates;

                this.cdr.markForCheck();
                setTimeout(() => {
                    this.scrollbar = new PerfectScrollbar('#reminder-list', {
                        wheelPropagation: false,
                        wheelSpeed: 0.5,
                    });
                });
            },
            error: (err) => console.error(err),
        });
    }

    private getCounters() {
        const requests = [
            this.dataService.get('/api/kpi/companies'),
            this.dataService.get(
                `/api/statistics?model=Payment&select=sum(casewhen((Payment.IsCustomerPayment eq 'true' and Payment.StatusCode eq '44018' )\,1\,0)) as payments`,
            ),
            this.dataService.get(
                `/api/biz/filetags/IncomingMail|IncomingEHF|IncomingTravel|IncomingExpense|Upload/0?action=get-supplierInvoice-inbox-count`,
            ),
        ];

        if (this.featurePermissionService.canShowUiFeature('ui.sales.reminders')) {
            // only checks appfrontend theme specific blacklists, not actual api permissions, catch eventual 403 error
            requests.push(this.reminderService.getCountsForReminderView().pipe(catchError(() => of([]))));
        }

        return forkJoin(requests).pipe(
            map(([kpi, paymentsWithoutMatch, inboxCount, readyForReminding]) => {
                const counters: any = {};

                const companyKpi = (kpi || []).find((c) => c.ID === this.authService.activeCompany.ID)?.Kpi || [];
                companyKpi.forEach((kpiItem) => {
                    if (kpiItem.Name === 'ToBePayed') {
                        counters.invoicesReadyForPayment = kpiItem.Counter;
                    }
                });

                if (paymentsWithoutMatch?.Data && paymentsWithoutMatch.Data[0]) {
                    counters.paymentWithoutMatch = paymentsWithoutMatch.Data[0].payments || 0;
                }
                counters.inbox = inboxCount;
                counters.invoicesReadyForReminder = (readyForReminding ?? [])
                    .filter((x) => x.RuleID > 0)
                    .reduce((acc, curr) => acc + curr?.CountReadyForReminding, 0);

                return counters;
            }),
        );
    }

    private getPublicDueDates() {
        return this.dataService.get('/api/biz/deadlines?action=number-of-days-filtered&nrOfDays=30').pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
            map((duedates: FinancialDeadline[]) => {
                return duedates
                    .sort((a, b) => (rigDate(a.Deadline).isAfter(rigDate(b.Deadline)) ? 1 : -1))
                    .map((dueDate) => {
                        const daysRemaining = rigDate(dueDate.Deadline).endOf('day').diff(rigDate(), 'days');
                        let cssClass;
                        let daysLabel;

                        if (daysRemaining < 7) {
                            cssClass = 'warn';
                        }

                        if (daysRemaining <= 0) {
                            cssClass = 'bad';
                            daysLabel = 'I dag';
                        } else if (daysRemaining === 1) {
                            daysLabel = 'I morgen';
                        } else {
                            daysLabel = daysRemaining + ' dager';
                        }

                        dueDate['_class'] = cssClass;
                        dueDate['_daysLabel'] = daysLabel;
                        return dueDate;
                    });
            }),
        );
    }

    private getApprovals() {
        return this.approvalService
            .getApprovals({
                status: ApprovalStatus.Active,
                onlyForCurrentUser: true,
            })
            .pipe(
                map((approvals) => {
                    const timesheets = [];
                    const invoices = [];

                    approvals?.forEach((approval) => {
                        const task = approval?.Task;
                        const model = task?.Model?.Name;

                        if (model === 'SupplierInvoice') {
                            const route = this.authService.canActivateRoute(
                                this.authService.currentUser,
                                '/accounting/bills',
                            )
                                ? `/accounting/bills/${approval.Task.EntityID}`
                                : `/assignments/approvals?showCompleted=false&id=${approval.ID}`;

                            const textParts = task.Title.split(/-(.+)/);

                            approval['_route'] = `/assignments/approvals?showCompleted=false&id=${approval.ID}`;
                            approval['_label'] = `Godkjenn regning fra ${textParts[0]}`; // TODO: show amount?
                            invoices.push(approval);
                        } else if (model === 'WorkItemGroup') {
                            let text = (task.Title || '').split('(')[0];
                            text = text.charAt(0).toLowerCase() + text.slice(1);
                            approval['_label'] = 'Godkjenn ' + text;
                            timesheets.push(approval);
                        }
                    });

                    return {
                        invoices: invoices,
                        timesheets: timesheets,
                    };
                }),
            );
    }

    private getTasks(ignoreCache = false) {
        const endpoint =
            '/api/statistics?model=Task&select=ID as ID,Title as Title,ModelID as ModelID,Model.Name as Name,EntityID as EntityID,' +
            `StatusCode as StatusCode,Type as Type,UserID as UserID&filter=UserID eq <userID> and ` +
            `StatusCode ne 50030 and Type ne 1&top=50&expand=model&orderby=ID desc`;

        return this.dataService.get(endpoint, ignoreCache).pipe(
            map((res) => res?.Data || []),
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
        );
    }

    createOrEditTask(task?: Task) {
        this.modalService.open(TaskModal, { data: task }).onClose.subscribe((changes) => {
            if (changes) {
                this.getTasks(true).subscribe((tasks) => {
                    this.tasks = tasks;
                    this.cdr.markForCheck();
                });
            }
        });
    }

    goToInvoiceApprovals() {
        if (this.authService.canActivateRoute(this.authService.currentUser, '/accounting/bills')) {
            this.router.navigateByUrl(
                `/accounting/bills?filter=ForApproval&assignee=${this.authService.currentUser.ID}`,
            );
        } else {
            this.router.navigateByUrl('/assignments/approvals?showCompleted=false');
        }
    }

    goToInbox() {
        if (theme.theme === THEMES.UE) {
            this.router.navigateByUrl('/accounting/bills?filter=Inbox');
        } else {
            this.router.navigateByUrl('/accounting/inbox');
        }
    }
}
