<section class="main-container" *ngIf="companySettings">
    <header class="flex align-center">
        <span> Kom igang </span>
        <i class="material-icons pull-right" #toggle> more_horiz </i>

        <dropdown-menu [trigger]="toggle">
            <ng-template>
                <section
                    class="dropdown-menu-item"
                    *ngIf="(service?.viewMode | async) === 1"
                    (click)="service.setAndStoreViewMode(2)"
                >
                    Minimer
                </section>

                <section
                    class="dropdown-menu-item"
                    *ngIf="(service?.viewMode | async) === 2"
                    (click)="service.setAndStoreViewMode(1)"
                >
                    Åpne
                </section>

                <section class="dropdown-menu-item" *ngIf="true" (click)="hide()">Skjul helt</section>
            </ng-template>
        </dropdown-menu>
    </header>
    <section class="startup-task-body border-top container-for-spinner" *ngIf="(service?.viewMode | async) === 1">
        <section *ngIf="service?.busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section class="left-container">
            <section class="header-container flex-column justify-center">
                <strong>Kom i gang med {{ appName }}</strong>
                <p class="subtitle">For å komme raskt i gang, har vi satt opp noen enkle steg du kan følge</p>
            </section>

            <section class="task-container" *ngIf="!busy && service?.tasks$ | async as tasks">
                <start-invoicing-task [task]="tasks.start_invoicing" [companySettings]="companySettings">
                </start-invoicing-task>

                <register-opening-balance-task [task]="tasks.register_opening_balance"> </register-opening-balance-task>

                <connect-to-altinn-task *ngIf="!isDNB" [task]="tasks.connect_to_altinn"> </connect-to-altinn-task>

                <activate-ehf-task [task]="tasks.activate_ehf" [companySettings]="companySettings"> </activate-ehf-task>

                <first-product-task *ngIf="isDNB" [task]="tasks.first_product"> </first-product-task>
            </section>
        </section>

        <section class="right-container border-left">
            <section class="header-container">
                <strong>Anbefalte snarveier</strong>
            </section>

            <section class="shortcut-container">
                <startup-shortcuts [companySettings]="companySettings"></startup-shortcuts>
            </section>
        </section>
    </section>
</section>
