<section role="dialog" class="uni-modal" style="width: 45rem; max-height: 80vh; min-height: 30rem">
    <header>{{ editMode ? 'Endre tekstmal' : 'Ny tekstmal' }}</header>

    <article>
        <section>
            <section>
                <label class="uni-label">
                    <span>Visningsnavn *</span>
                    <input
                        [(ngModel)]="template.Title"
                        type="text"
                        [disabled]="template.IsSystemTemplate"
                        name="title"
                        #title="ngModel"
                        required
                    />

                    @if (title.invalid && (title.dirty || title.touched)) {
                        <div class="color-bad">Tittel er påkrevd.</div>
                    }
                </label>
            </section>
        </section>

        <section class="flex-column h-100">
            <span class="label">Tekst</span>
            <div class="flex-column flex-1 h-100">
                <div class="tb">
                    <button
                        (click)="toggleStyle('bold')"
                        title="Fet skrift"
                        class="btn-bold"
                        onmousedown="event.preventDefault()"
                    >
                        B
                    </button>
                    <button
                        (click)="toggleStyle('italic')"
                        title="Skråstilt skrift"
                        class="btn-italic"
                        onmousedown="event.preventDefault()"
                    >
                        I
                    </button>
                    <button
                        (click)="toggleStyle('underline')"
                        title="Underlinje skrift"
                        class="btn-underline"
                        onmousedown="event.preventDefault()"
                    >
                        U
                    </button>
                    <button (click)="clearEditor()" title="Tøm kommentar">
                        <uni-icon [icon]="icon" [svgSize]="20"></uni-icon>
                    </button>
                </div>

                <div
                    id="editor"
                    class="border rounded flex-1 custom-scrollbar"
                    #editor
                    (input)="checkForChanges()"
                    contenteditable="true"
                    [attr.aria-busy]="loading"
                ></div>
            </div>
        </section>
    </article>

    <footer>
        <button (click)="cancel()" class="secondary">Avbryt</button>

        <button class="c2a" (click)="confirm()" [attr.aria-busy]="busy">
            {{ editMode ? 'Lagre endring' : 'Lagre' }}
        </button>
    </footer>
</section>
