import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentLayout, ItemSource } from '@uni-entities';
import { BizHttp, UniHttp } from '@uni-framework/core/http';
import { cloneDeep } from 'lodash-es';
import { map, Observable, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ItemSourceService extends BizHttp<ItemSource> {
    private layout$: Observable<ComponentLayout>;
    private lookupCache: Record<string, Observable<any[]>> = {};

    constructor(
        http: UniHttp,
        private httpClient: HttpClient,
    ) {
        super(http);
        this.relativeURL = 'itemsources';
        this.entityType = ItemSource.EntityType;
        this.DefaultOrderBy = null;
        this.defaultExpand = ['Details'];
    }

    getLayout() {
        if (!this.layout$) {
            this.layout$ = this.httpClient
                .get<ComponentLayout>('/api/metadata/layout/agri-details')
                .pipe(shareReplay(1));
        }

        return this.layout$.pipe(map((res) => cloneDeep(res)));
    }

    agriDataLookup(lookupUrl: string) {
        const key = super.hashFnv32a(lookupUrl);
        let data$ = this.lookupCache[key];

        if (!data$) {
            data$ = this.httpClient.get<any[]>(lookupUrl).pipe(shareReplay(1));
            this.lookupCache[key] = data$;
        }

        return data$;
    }
}
