import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommentType, FinancialReportCommentService } from '@app/services/accounting/financialReportCommentService';
import { FinancialReportComment } from '@uni-entities';
import { UniForm } from '@uni-framework/ui/uniform';
import { ConfirmActions, IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { cloneDeep } from 'lodash-es';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'report-template-editor-modal',
    templateUrl: './report-template-editor-modal.html',
    styleUrls: ['./report-template-editor-modal.sass'],
})
export class ReportTemplateEditorModal implements IUniModal, OnInit {
    @ViewChild(UniForm) form: UniForm;
    @ViewChild('editor') editor: ElementRef;
    @Input() options?: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    saving = false;
    loading = false;
    busy = false;
    hasChanges = false;
    editMode: boolean;
    template: FinancialReportComment;

    icon: string = 'delete';

    constructor(
        private toastService: ToastService,
        private financialReportCommentService: FinancialReportCommentService,
        private errorService: ErrorService,
    ) {}

    ngOnInit() {
        this.template =
            cloneDeep(this.options?.data?.template) ??
            <FinancialReportComment>{
                Title: '',
                EntityType: 'financialreport',
                CommentType: CommentType.Template,
                EntityID: 0,
                IsSystemTemplate: 0,
            };

        this.editMode = this.options?.data?.editMode;
    }

    ngAfterViewInit() {
        this.showTemplate(this.template);
    }

    toggleStyle(textStyle: 'bold' | 'italic' | 'underline') {
        try {
            document.execCommand(textStyle);
        } catch {}
    }

    checkForChanges() {
        this.hasChanges = this.analyzeChanges();
    }

    async confirm() {
        // required to have a title in order to save template
        const isValid = this.template.Title;
        if (isValid) {
            const template = this.mergeNewCommentsIntoExisting();

            this.busy = true;
            this.financialReportCommentService.saveTemplate(template).subscribe(
                () => this.onClose.emit(),
                (err) => {
                    this.errorService.handle(err);
                    this.busy = false;
                },
            );

            return this.onClose.emit({
                action: ConfirmActions.ACCEPT,
                template: template,
            });
        } else {
            this.toastService.addToast('Kan ikke lagre tekstmal uten tittel.', ToastType.bad, 3);
        }
    }

    cancel() {
        return this.onClose.emit({ action: ConfirmActions.CANCEL });
    }

    private analyzeChanges(): boolean {
        const updatedTemplate = this.loadFromEditor();
        if (!this.editMode && !this.template) return false;

        if (updatedTemplate.Text.length != this.template.Text.length) {
            return true;
        }

        const oldComment = this.template;
        const newComment = updatedTemplate;
        if (oldComment.Text != newComment.Text) {
            return true;
        }

        return false;
    }

    private showTemplate(template: FinancialReportComment) {
        if (!this.editor) return;
        const el = <HTMLElement>this.editor.nativeElement;

        if (this.editMode) {
            el.innerHTML = template.Text;
        }

        // Put actual html back into Text to make sure we detect changes correctly
        if (template) {
            template.Text = el.innerHTML;
        }
    }

    clearEditor() {
        if (!this.editor) return;
        const el = <HTMLElement>this.editor.nativeElement;
        el.innerHTML = '';
    }

    private loadFromEditor(convertDivs = false): FinancialReportComment {
        const editor = this.editor.nativeElement;
        let text = editor.innerHTML;
        if (convertDivs) {
            if (text && text.indexOf('<div')) {
                text = text.replace(new RegExp('<div>', 'g'), "<p style='margin:0'>");
                text = text.replace(new RegExp('</div>', 'g'), '</p>');
            }
        }

        const comment = { ...this.template, Text: text };

        if (!this.editMode) {
            this.showTemplate(comment);
        }

        return comment;
    }

    // Prevent user from pasting formatted text
    @HostListener('paste', ['$event']) onPaste(e: any) {
        if (!e.target.isContentEditable) return;
        const items = e.clipboardData.items;
        for (const item of items) {
            if (item.type.indexOf('text/plain') === 0) {
                item.getAsString((res) => {
                    if (res) {
                        this.insertIntoEditor(res);
                    }
                });
            }
        }
        e.preventDefault();
    }

    private insertIntoEditor(text: string, scrollIntoView = false, appendLast = false) {
        const editor: HTMLElement = this.editor.nativeElement;

        const sel = document.getSelection();
        const newElement = this.createTemplateElement(text);
        let current = <HTMLElement>sel.focusNode;

        if (appendLast) {
            current = this.editor.nativeElement;
            current = <HTMLElement>(
                (current.childNodes.length
                    ? current.childNodes.item(current.childNodes.length - 1)
                    : <HTMLElement>sel.focusNode)
            );
        }

        if (current && this.isDecendantNodeOf(current, editor)) {
            if (sel.type === 'Range') {
                const nRange = sel.rangeCount;
                for (let i = 0; i < nRange; i++) {
                    if (i == 0) {
                        sel.getRangeAt(i).deleteContents();
                        sel.getRangeAt(i).insertNode(newElement);
                        break;
                    }
                }
            } else {
                current.after(newElement);
            }
        } else {
            editor.prepend(newElement);
        }

        this.hasChanges = true;

        if (scrollIntoView) {
            newElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }

    isDecendantNodeOf(el: HTMLElement, parent: HTMLElement) {
        if (el && el.parentElement && el.parentElement === parent) return true;
        return el && el.parentElement ? this.isDecendantNodeOf(el.parentElement, parent) : false;
    }

    private createTemplateElement(text: string): HTMLElement {
        let par: HTMLElement = document.createElement('p');
        let actualText = this.convertNorwegianCharacterCodes(text);
        if (text.startsWith('# ')) {
            par.appendChild(newElement('B', '', newElement('U', actualText.substring(2))));
        } else if (text.startsWith('## ')) {
            par.appendChild(newElement('B', actualText.substring(2)));
        } else {
            par.innerText = actualText;
        }
        par.style.margin = '0';
        return par;
    }

    private convertNorwegianCharacterCodes(value: string): string {
        if (value && value.length > 0) {
            const characterMap = {
                '&#230;': 'æ',
                '&#248;': 'ø',
                '&#229;': 'å',
                '&#198;': 'Æ',
                '&#216;': 'Ø',
                '&#197;': 'Å',
            };
            if (value.indexOf('&#') >= 0) {
                let output = '' + value;
                for (let key in characterMap) {
                    output = output.replace(new RegExp(key, 'g'), characterMap[key]);
                }
                return output;
            }
        }
        return value;
    }

    private mergeNewCommentsIntoExisting(): FinancialReportComment {
        const edited = this.loadFromEditor(true);
        if (!this.template) return edited;

        const template = this.template;
        template.Text = edited.Text;

        return this.template;
    }
}

function newElement(tagName: string, text?: string, subElement?: HTMLElement): HTMLElement {
    const nd = document.createElement(tagName);
    if (text) {
        nd.innerText = text;
    }
    if (subElement) {
        nd.appendChild(subElement);
    }
    return nd;
}
