import { Component, Input, OnInit, Signal, computed } from '@angular/core';
import { ConfirmActions, UniModalService } from '@uni-framework/uni-modal';
import { ReportTemplateEditorModal } from '../report-template-editor-modal/report-template-editor-modal';
import { CommentType, FinancialReportCommentService } from '@app/services/accounting/financialReportCommentService';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { FinancialReportComment } from '@uni-entities';
import { Observable, of } from 'rxjs';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'text-templates',
    templateUrl: './text-templates.html',
    styleUrls: ['./text-templates.sass'],
})
export class TextTemplates {
    @Input() visible: boolean;
    @Input() id: number;

    currentYear: number;
    kpiMonths: { from: number; to: number; label?: string };
    periodEndDate: Date;

    groupedTemplates = computed(() => {
        const templates = this.financialReportCommentService.templates();
        return {
            systemTemplates: templates.filter((t) => t.IsSystemTemplate === 1),
            customTemplates: templates.filter((t) => t.IsSystemTemplate === 0),
        };
    });

    deleteIcon: string = 'delete';
    loading = false;

    constructor(
        private uniModalService: UniModalService,
        private financialReportCommentService: FinancialReportCommentService,
        private errorService: ErrorService,
    ) {}

    ngOnChanges() {
        if (!this.visible) return;

        this.financialReportCommentService.loadTemplates();
    }

    addOrEditTemplate(template?, edit?: boolean) {
        this.uniModalService.open(ReportTemplateEditorModal, {
            data: template ? { template, editMode: edit } : undefined,
            closeOnClickOutside: false,
            closeOnEscape: false,
        });
    }

    deleteTemplate(template: FinancialReportComment) {
        const modalOptions = {
            header: 'Slett tekstmal',
            message: 'Ønsker du å slette denne tekstmalen?',
            buttonLabels: {
                accept: 'Slett',
                cancel: 'Avbryt',
            },
        };

        this.uniModalService.confirm(modalOptions).onClose.subscribe((res) => {
            if (res === ConfirmActions.ACCEPT) {
                template.Deleted = true;
                return this.saveTemplate(template).subscribe(
                    () => {},
                    (err) => {
                        this.errorService.handle(err);
                        template.Deleted = false;
                    },
                );
            }
        });
    }

    copyTemplate(template: FinancialReportComment) {
        const newCopy = new FinancialReportComment();
        newCopy.Title = template.Title + ' kopi';
        newCopy.Text = template.Text;
        newCopy.EntityType = 'financialreport';
        newCopy.CommentType = CommentType.Template;
        newCopy.EntityID = 0;
        newCopy.IsSystemTemplate = 0;

        this.saveTemplate(newCopy).subscribe(
            () => {},
            (err) => this.errorService.handle(err),
        );
    }

    public saveTemplate(template: FinancialReportComment): Observable<any> {
        return this.financialReportCommentService.saveTemplate(template);
    }
}
