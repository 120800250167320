import { Component, EventEmitter, OnInit } from '@angular/core';
import { IModalOptions, IUniModal, UniModalService, ProductPurchasesModal } from '@uni-framework/uni-modal';
import {
    ElsaProduct,
    ElsaProductType,
    ElsaProductStatusCode,
    ElsaAgreementStatus,
    ElsaPurchaseStatus,
} from '@app/models';
import { ElsaProductService } from '@app/services/elsa/elsaProductService';
import { ElsaPurchase } from '@app/models';
import { parse } from 'marked';
import { CompanySettings } from '@uni-entities';
import { PurchaseTraveltextModal } from '../purchase-traveltext-modal/purchase-traveltext-modal';
import { ErrorService } from '@app/services/common/errorService';
import { ElsaAgreementService } from '@app/services/elsa/elsaAgreementService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';
import { StepPriceModal } from '@app/components/marketplace/step-price-modal/step-price-modal';

@Component({
    selector: 'uni-product-subscribe-modal',
    templateUrl: './subscribe-modal.html',
    styleUrls: ['./subscribe-modal.sass'],
})
export class SubscribeModal implements IUniModal, OnInit {
    options: IModalOptions = {};
    onClose: EventEmitter<any> = new EventEmitter();

    product: ElsaProduct;
    canPurchaseProducts: boolean;
    hasOrgNr: boolean;
    tooltipText: string;
    busy: boolean;
    isConsentStep = false;
    elsaPurchaseStatusEnum = ElsaPurchaseStatus;
    companySettings: CompanySettings;

    htmlContent: string;
    videoMarkup: string;

    action: {
        label: string;
        click: () => void;
    };

    constructor(
        private errorService: ErrorService,
        private modalService: UniModalService,
        private elsaProductService: ElsaProductService,
        private elsaPurchaseService: ElsaPurchaseService,
        private elsaAgreementService: ElsaAgreementService,
    ) {}

    ngOnInit() {
        const data = this.options.data || {};
        this.product = data.product;
        this.canPurchaseProducts = data.canPurchaseProducts;
        this.hasOrgNr = data.hasOrgNr;
        this.companySettings = data.companySettings;

        // client id placeholder should be more than 4 chars
        const hasValidClientId = data.product.ClientID?.length > 4;

        const isIntegration = this.product.ProductType === ElsaProductType.Integration;
        const isTravelText = this.product.Name.trim().toLowerCase() === 'traveltext';
        const showAnnualSettlementWizardOnClose =
            (this.product.Name.trim().toLowerCase().includes('annualsettlement') ||
                this.product.Name.trim().toLowerCase().includes('forhaandsfastsetting')) &&
            !this.product['_isBought'];

        if (this.product.MarkdownContent) {
            try {
                const decoded = decodeURI(this.product.MarkdownContent);
                this.htmlContent = parse(decoded) || '';
            } catch (e) {
                console.error(e);
            }
        }

        if (this.product.VideoUrl) {
            this.videoMarkup = this.getVideoMarkup(this.product.VideoUrl);
        }

        this.product.ProductPrices = this.elsaProductService.findContractTypeProductPrice(this.product.ProductPrices);
        if (!this.product['_price']) {
            this.product['_price'] = this.elsaProductService.mapPrice(this.product);
        }

        if (!this.product['_priceText']) {
            this.product['_priceText'] = this.elsaProductService.ProductTypeToPriceText(this.product);
        }

        if (this.product['_hasStepPrice'] === undefined) {
            this.product['_hasStepPrice'] = this.product.ProductPrices.some((price) => price.Steps?.length > 0);
        }

        if (!this.canPurchaseProducts) {
            this.tooltipText = 'Du må være administrator for å kjøpe produkter';
        } else if (!this.hasOrgNr) {
            this.tooltipText = 'Vennligst fyll inn selskapets organisasjonsnummer for å kjøpe produkter';
        }

        if (this.product?.ProductStatus !== ElsaProductStatusCode.Live) {
            return;
        }

        if (isTravelText) {
            // remove the isBought check when we have a solution for inviting new traveltext users
            if (this.companySettings && !this.product['_isBought']) {
                this.action = {
                    label: 'Aktiver',
                    click: () => this.openTravelTextModal(),
                };
            }
            return;
        }

        if (showAnnualSettlementWizardOnClose) {
            this.action = {
                label: 'Kjøp',
                click: () => this.onClose.emit('annualSettlement'),
            };
            return;
        }

        if (this.product.IsPerUser) {
            this.action = {
                label: 'Velg brukere',
                click: () => this.manageUserPurchases(),
            };
            return;
        }

        if (this.product['_isBought']) {
            if (this.product['_activationFunction']) {
                this.action = this.product['_activationFunction'];
            }
            return;
        }

        if (isIntegration) {
            if (hasValidClientId) {
                this.action = {
                    label: 'Aktiver integrasjon',
                    click: () => this.purchaseProduct(),
                };
            }
            return;
        }

        if (
            this.product.ProductType !== ElsaProductType.BankProduct &&
            this.product.ProductType !== ElsaProductType.FileImport
        ) {
            this.action = {
                label: this.product.Price > 0 ? 'Kjøp' : 'Aktiver',
                click: () => this.purchaseProduct(),
            };
        }
    }

    manageUserPurchases() {
        if (this.canPurchaseProducts) {
            this.modalService.open(ProductPurchasesModal, {
                closeOnClickOutside: true,
                data: {
                    product: this.product,
                },
            });

            this.onClose.emit();
        }
    }

    onPurchaseClick() {
        if (
            !this.isConsentStep &&
            this.product.ProductAgreement &&
            this.product.ProductAgreement.AgreementStatus === ElsaAgreementStatus.Active
        ) {
            this.elsaAgreementService.GetByProductID(this.product.ID).subscribe(
                (agreement) => {
                    if (agreement?.AgreementText) {
                        try {
                            const decoded = decodeURI(agreement.AgreementText);
                            this.htmlContent = parse(decoded) || '';
                            this.isConsentStep = true;
                        } catch (err) {
                            console.error(err);
                        }
                    }
                },
                (err) => this.errorService.handle(err),
            );
        } else {
            this.action.click();
        }
    }

    openTravelTextModal() {
        this.modalService.open(PurchaseTraveltextModal, {
            data: {
                product: this.product,
                companySettings: this.companySettings,
            },
            closeOnClickOutside: false,
        });
        this.onClose.emit();
    }

    openStepPriceModal() {
        this.modalService.open(StepPriceModal, { data: this.product });
    }

    purchaseProduct() {
        if (!this.busy && this.canPurchaseProducts && this.hasOrgNr) {
            this.busy = true;
            const purchase: ElsaPurchase = {
                ID: null,
                ProductID: this.product.ID,
            };

            this.elsaPurchaseService.massUpdate([purchase]).subscribe(
                () => {
                    this.busy = false;
                    this.product['_isBought'] = true;
                    this.action = this.product['_activationFunction'];
                    if (this.action) {
                        this.action.click();
                    }
                    this.onClose.emit(true);
                },
                (err) => {
                    this.errorService.handle(err);
                    this.busy = false;
                },
            );
        }
    }

    private getVideoMarkup(videoUrl: string) {
        try {
            let videoCode;
            if (videoUrl.includes('youtu.be/')) {
                videoCode = videoUrl.split('youtu.be/')[1];
            } else if (videoUrl.includes('v=')) {
                const codePart = videoUrl.split('v=')[1];
                videoCode = codePart.split('&')[0];
            } else if (videoUrl.includes('/embed/')) {
                videoCode = videoUrl.split('/embed/')[1];
            }

            if (videoCode) {
                return `
                    <iframe
                        src="https://www.youtube.com/embed/${videoCode}"
                        width="560"
                        height="315"
                        frameborder="0"
                        allow="accelerometer;
                        autoplay;
                        encrypted-media;
                        gyroscope;
                        picture-in-picture"
                        allowfullscreen>
                    </iframe>
                `;
            }
        } catch (err) {
            console.error(err);
        }
    }
    openLink(url: string) {
        const target = this.product.ProductType === ElsaProductType.FileImport ? '_self' : '_blank';
        window.open(url, target);
        this.onClose.emit();
    }
}
