import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/authService';
import { CustomStorageService } from '@app/services/common/customStorageService';
import { ErrorService } from '@app/services/common/errorService';
import { CustomStorage } from '@uni-entities';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { UniModalService } from '@uni-framework/uni-modal';
import { BehaviorSubject } from 'rxjs';
import { theme, THEMES } from 'src/themes/theme';
import { TasksCompleteModal } from './complete-modal/complete-modal';
import { FeaturePermissionService } from '@app/featurePermissionService';

export interface StartupTask {
    label: string;
    completed: boolean;
    identifier: string;
    completeLabel: string;
}

export const STARTUP_TASK_VIEWMODE = 'STARTUP_TASK_VIEWMODE';
export const HAS_ADDED_STARTUPTASKS_MANUALLY = 'HAS_ADDED_STARTUPTASKS_MANUALLY';

@Injectable()
export class StartupTaskService {
    tasks$ = new BehaviorSubject({});
    viewMode = new BehaviorSubject(+this.browserStorageService.getItemFromCompany(STARTUP_TASK_VIEWMODE) || 1);
    customStorageObject: CustomStorage;
    busy = true;

    constructor(
        private modalService: UniModalService,
        private errorService: ErrorService,
        private browserStorageService: BrowserStorageService,
        private customStorageService: CustomStorageService,
        private permissionService: FeaturePermissionService,
        private authService: AuthService,
        private router: Router,
    ) {}

    init() {
        this.busy = true;
        this.getTaskState()
            .subscribe({
                next: (customStorageObject: CustomStorage) => {
                    this.customStorageObject =
                        customStorageObject || <CustomStorage>{ NameSpace: 'StartupTask', Shared: true };
                    this.customStorageObject.Data = JSON.parse(this.customStorageObject?.Data || null);
                    this.tasks$.next(this.getDefaultTasks(this.customStorageObject.Data));
                },
                error: (err) => this.errorService.handle(err),
            })
            .add(() => (this.busy = false));
    }

    markTaskAsCompleted(task: StartupTask) {
        const tasks = this.tasks$.getValue();
        tasks[task.identifier].completed = true;
        this.tasks$.next(tasks);

        this.customStorageObject.Data = JSON.stringify(this.getSavableCustomStorageObject(this.tasks$.getValue()));

        this.updateTaskState(this.customStorageObject)
            .subscribe({
                next: (updatedObject) => {
                    this.customStorageObject = updatedObject;
                    this.customStorageObject.Data = JSON.parse(this.customStorageObject?.Data || null);
                    this.tasks$.next(this.getDefaultTasks(this.customStorageObject.Data));
                },
            })
            .add(() => (this.busy = false));

        let hasMissing = false;

        for (let key in tasks) {
            if (!tasks[key].completed) {
                hasMissing = true;
            }
        }

        if (!hasMissing) {
            this.openCompleteModal();
        }
    }

    openCompleteModal() {
        this.modalService.open(TasksCompleteModal).onClose.subscribe((response) => {
            if (response) {
                this.setAndStoreViewMode(3);
            }
        });
    }

    setAndStoreViewMode(viewMode: number): void {
        this.viewMode?.next(viewMode);
        this.browserStorageService.setItemOnCompany(STARTUP_TASK_VIEWMODE, viewMode.toString());
    }

    getTaskState() {
        this.customStorageService.invalidateCache();
        return this.customStorageService.GetOneByQuery(`filter=NameSpace eq 'StartupTask'`);
    }

    updateTaskState(body) {
        return body.ID ? this.customStorageService.Put(body.ID, body) : this.customStorageService.Post(body);
    }

    checkAccessToCompleteStartupTasks() {
        return (
            this.router.url === '/' &&
            this.permissionService.canShowUiFeature('ui.dashboard.startup') &&
            this.authService.canActivateRoute(this.authService.currentUser, '/settings') &&
            this.authService.hasUIPermission(this.authService.currentUser, 'ui_accounting_journalentry')
        );
    }

    getSavableCustomStorageObject(data) {
        const savable = {};

        for (let key in data) {
            savable[key] = {
                completed: data[key].completed,
            };
        }

        return savable;
    }

    getDefaultTasks(taskState) {
        let tasks: any = {
            start_invoicing: {
                label: 'Klargjør for fakturering',
                completed: taskState?.start_invoicing?.completed || false,
                completeLabel: 'Fakturaoppsett fullført',
                identifier: 'start_invoicing',
                icon: 'play_arrow',
            },
            register_opening_balance: {
                label: 'Registrer åpningsbalanse',
                completed: taskState?.register_opening_balance?.completed || false,
                completeLabel: 'Åpningsbalanse registrert',
                identifier: 'register_opening_balance',
                icon: 'checklist',
            },
            activate_ehf: {
                label: 'Aktivere EHF-mottak',
                completed: taskState?.activate_ehf?.completed || false,
                completeLabel: 'EHF aktivert',
                identifier: 'activate_ehf',
                icon: 'toggle_on',
            },
        };

        if (theme.theme === THEMES.EXT02) {
            tasks.first_product = {
                label: 'Opprett ditt første produkt',
                completed: taskState?.first_product?.completed || false,
                completeLabel: 'Produkt opprettet',
                identifier: 'first_product',
                icon: 'inventory_2',
            };
        } else {
            tasks.connect_to_altinn = {
                label: 'Koble selskap mot Altinn',
                completed: taskState?.connect_to_altinn?.completed || false,
                completeLabel: 'Tilkoblet altinn',
                imageLink: 'config/themes/shared/connect_to_altinn.svg',
                identifier: 'connect_to_altinn',
                icon: 'add_link',
            };
        }

        return tasks;
    }
}
