import { map, take } from 'rxjs/operators';
import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { IUniModal, IModalOptions, UniModalService, UniBankAccountModal } from '@uni-framework/uni-modal';
import { UniForm, FieldType } from '@uni-framework/ui/uniform';
import { Payment, BankAccount, StatusCode } from '@uni-entities';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { UniAccountTypePipe } from '@uni-framework/pipes/uniAccountTypePipe';
import { BankAccountService } from '@app/services/accounting/bankAccountService';
import { PaymentCodeService } from '@app/services/accounting/paymentCodeService';
import { CurrencyCodeService } from '@app/services/common/currencyCodeService';
import { ErrorService } from '@app/services/common/errorService';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { StatisticsService } from '@app/services/common/statisticsService';

@Component({
    selector: 'add-payment-modal',
    templateUrl: './add-payment-modal.html',
    styleUrls: ['./add-payment-modal.sass'],
})
export class AddPaymentModal implements IUniModal {
    @ViewChild(UniForm, { static: true }) form: UniForm;

    @Input() options: IModalOptions;
    @Output() onClose = new EventEmitter();

    compactLayout: boolean = false;
    model: any = {};
    model$ = new BehaviorSubject(null);
    fields$ = new BehaviorSubject([]);

    private accountType: string;
    private fromBankAccountsList: any;
    private paymentCodes: any;

    constructor(
        private errorService: ErrorService,
        private statisticsService: StatisticsService,
        private modalService: UniModalService,
        private bankAccountService: BankAccountService,
        private toastService: ToastService,
        private paymentCodeService: PaymentCodeService,
        private numberFormat: NumberFormat,
        private uniAccountTypePipe: UniAccountTypePipe,
        private currencyCodeService: CurrencyCodeService,
    ) {}

    ngOnInit() {
        if (this.options) {
            this.compactLayout = this.options.data?.useCompactLayout;
            this.model = this.options.data?.model;

            this.model$.next(this.model);
            this.setupForm();
        }
    }

    ngOnDestroy() {
        this.model$.complete();
        this.fields$.complete();
    }

    private setupForm() {
        let model = this.model$.getValue();
        model.PaymentCodeID = model.PaymentCodeID || 1;

        forkJoin([
            this.bankAccountService.GetAll(
                `filter=CompanySettingsID eq 1 and (statuscode eq null or StatusCode eq ${StatusCode.Active})`,
            ),
            this.bankAccountService.GetAll(
                `filter=ID eq ${model.ToBankAccountID} or ( BusinessRelationID eq ${model.BusinessRelationID || 0} and (statuscode eq null or StatusCode eq ${StatusCode.Active}) )&distinct=true`,
            ),
            this.paymentCodeService.GetAll(null),
        ]).subscribe((data) => {
            this.fromBankAccountsList = data[0];
            this.fromBankAccountsList.forEach((account) => {
                account['_displayValue'] = account.Label
                    ? account.Label + ' - ' + this.numberFormat.asBankAcct(account.AccountNumber)
                    : account.BankAccountType
                      ? this.uniAccountTypePipe.transform(account.BankAccountType) +
                        ' - ' +
                        this.numberFormat.asBankAcct(account.AccountNumber)
                      : this.numberFormat.asBankAcct(account.AccountNumber);
            });

            model = this.setToBankAccountDataOnModel(model, data[1]);

            this.paymentCodes = data[2];
            if (!model.CurrencyCode?.Code) {
                this.currencyCodeService.get(model.CurrencyCodeID).subscribe((currencyCode) => {
                    model.CurrencyCode = currencyCode;
                    this.fields$.next(this.getFields());
                });
            } else {
                this.fields$.next(this.getFields());
            }

            this.model$.next(model);
        });
    }

    private setToBankAccountDataOnModel(model: Payment, accounts: BankAccount[]): Payment {
        model['ToBankAccountsList'] = accounts;

        let selectedAccount = model.ToBankAccountID && accounts.find((acc) => acc.ID === model.ToBankAccountID);
        if (!selectedAccount) {
            const defaultBankAccountID = model.BusinessRelation?.DefaultBankAccountID;
            selectedAccount =
                (defaultBankAccountID && accounts.find((acc) => acc.ID === defaultBankAccountID)) || accounts[0];
        }

        model.ToBankAccount = selectedAccount || null;
        model.ToBankAccountID = selectedAccount?.ID || null;

        return model;
    }

    private getDefaultBusinessRelationData() {
        if (this.model && this.model.BusinessRelation) {
            this.statisticsService
                .GetAllUnwrapped(
                    `model=BusinessRelation&select=BusinessRelation.ID as ID,` +
                        `BusinessRelation.Name as Name,Customer.ID,Supplier.ID,Employee.ID` +
                        `&filter=BusinessRelation.ID eq ${this.model.BusinessRelationID} and ` +
                        `BusinessRelation.Deleted eq 'false'&join=Customer on BusinessRelation.ID` +
                        ` eq Customer.BusinessRelationID Supplier on BusinessRelation.ID eq ` +
                        `Supplier.BusinessRelationID Employee on BusinessRelation.ID eq Employee.BusinessRelationID`,
                )
                .subscribe(
                    (res) => {
                        const businessObject = res.find((x) => x.ID === this.model.BusinessRelationID);

                        if (businessObject) {
                            this.accountType = businessObject
                                ? businessObject.CustomerID
                                    ? 'Customer'
                                    : businessObject.SupplierID
                                      ? 'Supplier'
                                      : businessObject.EmployeeID
                                        ? 'Employee'
                                        : ''
                                : '';
                        }
                    },
                    (err) => this.errorService.handle(err),
                );
            return of([this.model.BusinessRelation]);
        } else {
            return of([]);
        }
    }

    updateBusinessRelation(businessRelationID: number) {
        const businessRelationRequest = this.statisticsService.GetAllUnwrapped(
            `model=BusinessRelation&filter=BusinessRelation.ID eq ${businessRelationID}` +
                `&select=ID as ID,Name as Name,DefaultBankAccountID as DefaultBankAccountID`,
        );

        forkJoin([
            businessRelationRequest,
            this.bankAccountService.GetAll(`filter=BusinessRelationID eq ${businessRelationID}`),
        ]).subscribe({
            next: ([businessRelations, bankAccounts]) => {
                let model = this.model$.getValue();
                model.BusinessRelation = businessRelations[0];
                model = this.setToBankAccountDataOnModel(model, bankAccounts);
                this.model$.next(model);
            },
            error: (err) => this.errorService.handle(err),
        });
    }

    private getFields() {
        return [
            {
                EntityType: 'Payment',
                Property: 'FromBankAccountID',
                FieldType: FieldType.DROPDOWN,
                Label: 'Betales fra konto',
                Classes: 'full-width',
                Options: {
                    source: this.fromBankAccountsList,
                    valueProperty: 'ID',
                    displayProperty: '_displayValue',
                },
            },
            {
                EntityType: 'Payment',
                Property: 'BusinessRelationID',
                FieldType: FieldType.AUTOCOMPLETE,
                Label: 'Betales til',
                Hidden: this.compactLayout,
                Options: {
                    getDefaultData: () => this.getDefaultBusinessRelationData(),
                    displayProperty: 'Name',
                    valueProperty: 'ID',
                    template: (item) => {
                        return item
                            ? (item.CustomerID
                                  ? 'Kunde: '
                                  : item.SupplierID
                                    ? 'Leverandør: '
                                    : item.EmployeeID
                                      ? 'Ansatt: '
                                      : '') + item.Name
                            : '';
                    },
                    search: (query: string) => {
                        if (query === null) {
                            query = '';
                        }
                        return this.statisticsService
                            .GetAll(
                                `model=BusinessRelation` +
                                    `&select=BusinessRelation.ID as ID,BusinessRelation.Name as Name,` +
                                    `Customer.ID,Supplier.ID,Employee.ID` +
                                    `&join=Customer on BusinessRelation.ID eq Customer.BusinessRelationID Supplier ` +
                                    `on BusinessRelation.ID eq Supplier.BusinessRelationID Employee on BusinessRelation.ID` +
                                    ` eq Employee.BusinessRelationID&filter=BusinessRelation.Deleted eq 'false' and ` +
                                    `contains(BusinessRelation.Name,'${query}') and (isnull(Customer.ID,0) ne 0 or ` +
                                    `isnull(Supplier.ID,0) ne 0 or isnull(Employee.ID,0) ne 0)&top=20`,
                            )
                            .pipe(map((x) => (x.Data ? x.Data : [])));
                    },
                    events: {
                        select: (model: Payment) => {
                            this.updateBusinessRelation(model.BusinessRelationID);
                        },
                    },
                },
            },
            {
                EntityType: 'Payment',
                Property: 'ToBankAccountID',
                FieldType: FieldType.MULTIVALUE,
                Label: 'Betales til konto',
                Options: {
                    listProperty: 'ToBankAccountsList',
                    linkProperty: 'ID',
                    storeIdInProperty: 'ToBankAccountID',
                    storeResultInProperty: 'ToBankAccount',
                    template: (field) => this.numberFormat.asBankAcct(field.AccountNumber),
                    editor: (bankaccount) => {
                        if (!bankaccount || !bankaccount.ID) {
                            bankaccount = bankaccount || new BankAccount();
                            bankaccount.BusinessRelationID = this.model$.value.BusinessRelationID;
                            bankaccount.BankAccountType = this.accountType;
                        }
                        bankaccount['_saveBankAccountInModal'] = true;
                        const modal = this.modalService.open(UniBankAccountModal, {
                            data: bankaccount,
                        });

                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                },
            },
            {
                EntityType: 'Payment',
                Property: 'PaymentDate',
                FieldType: FieldType.LOCAL_DATE_PICKER,
                Label: 'Betalingsdato',
            },
            {
                EntityType: 'Payment',
                Property: 'DueDate',
                FieldType: FieldType.LOCAL_DATE_PICKER,
                Label: 'Forfallsdato',
                ReadOnly: true,
                Hidden: this.compactLayout,
            },
            {
                EntityType: 'Payment',
                Property: 'AmountCurrency',
                FieldType: FieldType.NUMERIC,
                Label: 'Beløp',
                Options: { decimalLength: 2 },
            },
            {
                EntityType: 'Payment',
                Property: 'CurrencyCode.Code',
                Label: 'Valuta',
                ReadOnly: true,
            },
            {
                EntityType: 'Payment',
                Property: 'PaymentID',
                Label: 'KID',
                Hidden: this.compactLayout,
            },
            {
                EntityType: 'CustomerInvoice',
                Property: 'InvoiceNumber',
                Label: 'Fakturanr',
                Hidden: this.compactLayout,
            },
            {
                EntityType: 'Payment',
                Property: 'Description',
                FieldType: FieldType.TEXTAREA,
                Label: 'Betalingsinformasjon',
                Classes: 'full-width',
            },
            {
                EntityType: 'Payment',
                Property: 'PaymentCodeID',
                FieldType: FieldType.DROPDOWN,
                Label: 'Type',
                Classes: 'full-width',
                Options: {
                    source: this.paymentCodes,
                    valueProperty: 'ID',
                    displayProperty: 'Name',
                    debounceTime: 200,
                },
                Hidden: this.compactLayout,
            },
        ];
    }

    public close(action: string) {
        if (action === 'ok') {
            const data = this.model$.getValue();
            // validate
            if (!data['PaymentDate']) {
                this.toastService.addToast('Betalingen mangler betalingsdato', ToastType.bad, 5);
                return false;
            } else if (!data['BusinessRelationID']) {
                this.toastService.addToast('"Betales til" er ugyldig!', ToastType.bad, 5);
                return false;
            } else if (!data['FromBankAccountID']) {
                this.toastService.addToast('Betalingen mangler "Fra konto"', ToastType.bad, 5);
                return false;
            } else if (!data['ToBankAccountID']) {
                this.toastService.addToast('Betalingen mangler "Til konto"', ToastType.bad, 5);
                return false;
            } else if (!data['AmountCurrency']) {
                this.toastService.addToast('Betalingen mangler "Beløp"', ToastType.bad, 5);
                return false;
            }
            data.Amount = data.AmountCurrency * (data.CurrencyExchangeRate ? data.CurrencyExchangeRate : 1);
            this.onClose.emit(this.model);
        } else {
            this.onClose.emit(false);
        }
    }
}
