import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FinancialReportComment } from '@uni-entities';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { UniMultiSelect } from '@uni-framework/ui/multi-select/multi-select';
import { IModalOptions } from '@uni-framework/uni-modal';

@Component({
    selector: 'text-templates-import-modal',
    templateUrl: './text-templates-import-modal.html',
    standalone: true,
    styleUrls: ['./text-templates-import-modal.sass'],
    imports: [FormsModule, CommonModule, UniFrameworkModule, UniMultiSelect],
})
export class TextTemplatesImportModal implements OnInit {
    @Input() options: IModalOptions;
    @Output() onClose = new EventEmitter();

    templates: FinancialReportComment[];
    selectedTemplates: number[];

    ngOnInit() {
        this.templates = this.options.data;
    }

    userSearchFn(searchText: string, template: FinancialReportComment) {
        searchText = searchText.toLowerCase();
        return template.Title?.toLowerCase().includes(searchText);
    }

    submit() {
        let templatesToAdd = [];

        this.selectedTemplates.forEach((templateID) => {
            templatesToAdd.push(this.templates.find((x) => x.ID === templateID).Text);
        });

        // need to reverse the order for templates to show in same order as user clicks
        templatesToAdd.reverse();
        this.onClose.emit(templatesToAdd);
    }
}
