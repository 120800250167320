import { NgModule } from '@angular/core';
import { UniFrameworkModule } from '../../../framework/frameworkModule';
import { AltinnAuthenticationModal } from './modals/AltinnAuthenticationModal';

import { UniDimensionTOFView } from './dimensions/dimensionForm';
import { UniQueryReadOnly } from './uniQuery/UniQueryReadOnly';
import { UniSummary } from './summary/summary';
import { ImageModal } from './modals/ImageModal';
import { UniAttachments } from './attachments/attachments';
import { AccrualModalV2 } from './modals/accrual-modal-v2/accrual-modal-v2';
import { Contacts } from './contact/contacts';
import { ContactModal } from './contact/modals/contact-modal';
import { MainContactCell } from './contact/main-contact-cell';
import { AddPaymentModal } from './modals/add-payment-modal/add-payment-modal';
import { ModelTreeView } from './modeltreeview/modeltreeview';
import { ModelTreeRelationNode } from './modeltreeview/relationNode';
import { IsoTimePipe, HoursPipe, NumberPipe } from './utils/pipes';
import { MinutesToHoursPipe } from './utils/pipes';
import { WorkTypeSystemTypePipe } from './utils/pipes';
import { PredefinedDescriptionList } from './predefinedDescriptions/predefinedDescriptionList';
import { BookPaymentManualModal } from './modals/bookPaymentManual';
import { ConfirmCreditedJournalEntryWithDate } from './modals/confirmCreditedJournalEntryWithDate';
import { ApiKeyComponent } from './apikey/apikeys';
import { ApikeyLineModal } from './apikey/modals/apikey-modal';
import { AltinnSettingsComponent } from './altinn-component/altinn-component';
import {
    WorkEditor,
    UniTimeModal,
    WorkitemTransferWizard,
    WorkitemTransferWizardFilter,
    WorkitemTransferWizardPreview,
    WorkitemTransferWizardProducts,
    InvoiceHours,
} from './timetrackingCommon';

import { BankInitModal } from './modals/bank-init-modal/bank-init-modal';
import { UniNewCompanyModal, NEW_COMPANY_VIEWS } from './modals/company-modals/new-company-modal';
import { TaskModal } from '../common/modals/task-modal/task-modal';
import { SelectDistributionPlanModal } from './modals/select-distribution-plan-modal/select-distribution-plan-modal';
import { EditSubEntityAgaZoneModal } from './modals/editSubEntityAgaZoneModal/editSubEntityAgaZoneModal';
import { ShowSubEntityHistoricAgaModal } from './modals/show-subentityhistoricaga-modal/show-subentityhistoricaga-modal';
import { ContractTypeCard } from './contract-type-card/contract-type-card';
import { ContractActivationWizard } from './contract-activation-wizard/contract-activation-wizard';
import { CompanyCreationWizard } from './company-creation-wizard/company-creation-wizard';
import { ContractTypesComparison } from './contract-types-comparison/contract-types-comparison';

import { RegisterAssetModal } from '@app/components/common/modals/register-asset-modal/register-asset-modal';
import { UniEntityEditorModule } from './uni-entity-editor/uni-entity-editor.module';
import { StandardVacationPayModalComponent } from './modals/standard-vacation-pay-modal/standard-vacation-pay-modal.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { CompanyBankAccountModal } from './modals/bank-account-modal/company-bank-account-modal';
import { CompanyBankAccountEdit } from './modals/bank-account-modal/bank-account-edit';
import { BankIDPaymentModal } from './modals/bankid-payment-modal/bankid-payment-modal';
import { ApiStatus } from './api-status/api-status';
import { CustomerAndSupplierEditModal } from '@app/components/common/modals/customer-and-supplier-edit-modal/customer-and-supplier-edit-modal';
import { ReminderSendingModal } from './modals/reminder-sending-modal/reminder-sending-modal';
import { SelectCustomersModal } from '../sales/common/selectCustomersModal';
import { MissingRolesBanner } from './missing-roles-banner/missing-roles-banner';
import { MissingProductsBanner } from './missing-products-banner/missing-products-banner';
import { RedirectToUnimicro } from './redirect-to-unimicro/redirect-to-unimicro';

import { RGBComponent } from './rgb-component/rgb-component';
import { BankIdSvgComponent } from './bankid-svg/bankid.component';
import { JournalEntryCounterModal } from './modals/journal-entry-counter-modal/journal-entry-counter-modal';
import { UniReconciliationStartDateModal } from './modals/reconciliation-startdate-modal';
import { NewAccountModalV2Module } from '@app/components/common/modals/new-account-modal-v2/new-account-modal-v2.module';
import { PaymentBatchApprovalModalComponent } from './modals/payment-batch-approval-modal/payment-batch-approval-modal.component';
import { ChangeSupplierGoodsModal } from './modals/changeGoodsModal/changeSupplierGoodsModal';
import { SelectProductForGoodsModal } from './modals/selectProductForGoodsModal/selectProductForGoodsModal';
import { GoodsList } from './modals/changeGoodsModal/GoodsList/goodsList';
import { FileFlowEmailModal } from './modals/file-flow-email-modal/file-flow-email-modal';
import { AltinnAuthenticationModal2 } from './modals/altinn-authentication-modal/altinn-authentication-modal';
import { SubscribeModal } from './modals/subscribe-modal/subscribe-modal';
import { FirstInvoiceWizardModal } from './modals/first-invoice-wizard/first-invoice-wizard';
import { AltinnSettingsModal } from './modals/altinn-settings-modal/connect-to-altinn-modal';
import { ToolbarModule } from './toolbar/toolbar.module';
import { PurchaseTraveltextModal } from './modals/purchase-traveltext-modal/purchase-traveltext-modal';
import { ImportModule } from './import/import.module';
import { EmployeeLeaveModalComponent } from './modals/employee-leave-modal/employee-leave-modal.component';
import { EmployeeSearchComponent } from './modals/employee-leave-modal/employee-search-component';
import { EmployeeLeaveCounterInfo } from './modals/employee-leave-modal/employee-leave-counter-info/employee-leave-counter-info';
import { OverlappingLeaveModal } from './modals/overlapping-leave-modal/overlapping-leave-modal';
import { MassLeaveRegistationModal } from './modals/employee-leave-modal/mass-registration-modal/mass-registration-modal';
import { EmployeeLeaveImportModalComponent } from './modals/employee-leave-modal/employee-leave-import-modal/employee-leave-import-modal.component';
import { AccrualDefinitionModal } from './modals/accrual-definition-modal/accrual-definition-modal';
import { PaymentMethodRegistrationModal } from './modals/payment-method-registration-modal/payment-method-registration-modal';
import { SignlREventListenerComponent } from './signalr-eventlistener/signalr-eventlistener';
import { StepPriceTable } from './step-price-table/step-price-table';
import { BillAssignmentModal } from './bill-assignment-modal/bill-assignment-modal';
import { ApprovalDetail } from './approval-detail/approval-detail';
import { InvoiceApprovalModal } from './invoice-approval-modal/invoice-approval-modal';
import { ResetAltinnAccountLinkModal } from './reset-altinn-account-link-modal/reset-altinn-account-link-modal';
import { CompanyValidationModal } from './modals/company-validation-modal/company-validation-modal';
import { VacationPayModal } from './modals/vacation-pay/vacation-pay-modal/vacation-pay-modal.component';
import { ItemSourceDetailsModalComponent } from './modals/item-source-details-modal/item-source-details-modal.component';
import { AttachmentsCell } from './attachmentsCell/attachmentsCell';

@NgModule({
    imports: [UniFrameworkModule, UniEntityEditorModule, DashboardModule, ToolbarModule, ImportModule],
    declarations: [
        PredefinedDescriptionList,
        SubscribeModal,
        AccrualModalV2,
        AccrualDefinitionModal,
        AltinnAuthenticationModal,
        ConfirmCreditedJournalEntryWithDate,
        UniQueryReadOnly,
        UniSummary,
        ImageModal,
        UniAttachments,
        AddPaymentModal,
        ModelTreeView,
        ModelTreeRelationNode,
        UniDimensionTOFView,
        VacationPayModal,
        SelectCustomersModal,
        PaymentBatchApprovalModalComponent,

        Contacts,
        ContactModal,
        MainContactCell,

        IsoTimePipe,
        HoursPipe,
        NumberPipe,
        MinutesToHoursPipe,
        WorkTypeSystemTypePipe,

        BookPaymentManualModal,

        ApiKeyComponent,
        ApikeyLineModal,

        BankInitModal,

        WorkEditor,
        UniTimeModal,
        WorkitemTransferWizard,
        WorkitemTransferWizardFilter,
        WorkitemTransferWizardPreview,
        WorkitemTransferWizardProducts,
        InvoiceHours,

        UniNewCompanyModal,
        TaskModal,
        SelectDistributionPlanModal,
        ...NEW_COMPANY_VIEWS,
        EditSubEntityAgaZoneModal,
        ShowSubEntityHistoricAgaModal,
        ContractTypeCard,
        ContractActivationWizard,
        CompanyCreationWizard,
        ContractTypesComparison,
        RegisterAssetModal,
        StandardVacationPayModalComponent,
        BankIDPaymentModal,
        CustomerAndSupplierEditModal,
        CompanyBankAccountModal,
        CompanyBankAccountEdit,
        ApiStatus,
        ReminderSendingModal,
        RGBComponent,
        ChangeSupplierGoodsModal,
        SelectProductForGoodsModal,
        GoodsList,
        BankIdSvgComponent,
        UniReconciliationStartDateModal,
        JournalEntryCounterModal,
        FileFlowEmailModal,
        MissingRolesBanner,
        MissingProductsBanner,
        RedirectToUnimicro,
        AltinnAuthenticationModal2,
        FirstInvoiceWizardModal,
        AltinnSettingsComponent,
        AltinnSettingsModal,
        PurchaseTraveltextModal,
        EmployeeLeaveModalComponent,
        EmployeeSearchComponent,
        EmployeeLeaveCounterInfo,
        OverlappingLeaveModal,
        MassLeaveRegistationModal,
        EmployeeLeaveImportModalComponent,
        PaymentMethodRegistrationModal,
        SignlREventListenerComponent,
        StepPriceTable,
        BillAssignmentModal,
        InvoiceApprovalModal,
        ApprovalDetail,
        CompanyValidationModal,
        ResetAltinnAccountLinkModal,
        ItemSourceDetailsModalComponent,
        AttachmentsCell,
    ],
    exports: [
        DashboardModule,
        NewAccountModalV2Module,
        ToolbarModule,

        SubscribeModal,
        PredefinedDescriptionList,
        ConfirmCreditedJournalEntryWithDate,
        AccrualModalV2,
        AltinnAuthenticationModal,
        UniQueryReadOnly,
        UniSummary,
        ImageModal,
        UniAttachments,
        AddPaymentModal,
        UniDimensionTOFView,
        ModelTreeView,
        ModelTreeRelationNode,
        EditSubEntityAgaZoneModal,
        ShowSubEntityHistoricAgaModal,
        RGBComponent,
        PaymentBatchApprovalModalComponent,

        TaskModal,

        Contacts,

        IsoTimePipe,
        MinutesToHoursPipe,
        HoursPipe,
        NumberPipe,
        WorkTypeSystemTypePipe,

        ApiKeyComponent,
        ApikeyLineModal,

        WorkEditor,
        UniTimeModal,
        WorkitemTransferWizard,
        WorkitemTransferWizardFilter,
        WorkitemTransferWizardPreview,
        WorkitemTransferWizardProducts,
        InvoiceHours,
        UniEntityEditorModule,
        ContractTypeCard,
        ContractActivationWizard,
        CompanyCreationWizard,
        ContractTypesComparison,

        RegisterAssetModal,
        StandardVacationPayModalComponent,
        BankIDPaymentModal,
        CustomerAndSupplierEditModal,
        CompanyBankAccountModal,
        CompanyBankAccountEdit,
        ApiStatus,
        ReminderSendingModal,
        MissingRolesBanner,
        MissingProductsBanner,
        RedirectToUnimicro,

        ChangeSupplierGoodsModal,
        SelectProductForGoodsModal,
        GoodsList,
        BankIdSvgComponent,
        JournalEntryCounterModal,
        FileFlowEmailModal,
        AltinnAuthenticationModal2,
        AltinnSettingsComponent,
        PurchaseTraveltextModal,
        ImportModule,
        EmployeeLeaveModalComponent,
        MassLeaveRegistationModal,
        PaymentMethodRegistrationModal,
        SignlREventListenerComponent,
        StepPriceTable,
        BillAssignmentModal,
        ApprovalDetail,
        InvoiceApprovalModal,
        CompanyValidationModal,
        ResetAltinnAccountLinkModal,
        ItemSourceDetailsModalComponent,
        AttachmentsCell,
    ],
})
export class AppCommonModule {}
