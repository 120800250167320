<section role="dialog" class="uni-modal uni-redesign">
    <header>Bruk tekstmal</header>

    <article>
        <uni-multi-select
            [(ngModel)]="selectedTemplates"
            [items]="templates"
            [bindLabel]="'Title'"
            [searchFn]="userSearchFn"
            [multiple]="true"
            [bindValue]="'ID'"
            [placeholder]="'Søk og velg tekstmaler'"
            [label]="'Velg tekstmal(er)'"
            [icon]="'article'"
            [appendTo]="'body'"
        >
        </uni-multi-select>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit(false)">Avbryt</button>
        <button class="c2a" (click)="submit()">Bruk</button>
    </footer>
</section>
