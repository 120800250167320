import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ItemSourceService } from '@app/services/common/itemSourceService';
import { FieldType, UniFieldLayout } from '@uni-framework/ui/uniform';
import { ErrorService } from '@app/services/common/errorService';
import { environment } from 'src/environments/environment';
import { get } from 'lodash-es';

@Component({
    selector: 'uni-item-source-details-modal',
    templateUrl: './item-source-details-modal.component.html',
    styleUrl: './item-source-details-modal.component.sass',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemSourceDetailsModalComponent implements IUniModal, OnInit, OnDestroy {
    options: IModalOptions = {};
    onClose = new EventEmitter<any>();

    fields$ = new BehaviorSubject([]);
    itemSource$ = new BehaviorSubject({});

    constructor(
        private http: HttpClient,
        private itemSourceService: ItemSourceService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.loadFields();
        if (this.options.data?.items) {
            this.itemSource$.next(this.options.data?.items);
        }
    }

    ngOnDestroy() {
        this.fields$.complete();
        this.itemSource$.complete();
    }

    private loadFields() {
        this.itemSourceService.getLayout().subscribe({
            next: (res) => {
                const fields = res?.Fields.map((field) => {
                    if (typeof field?.Options === 'string') {
                        field.Options = JSON.parse(field.Options);
                    }

                    return field;
                });

                this.setFormFields(fields as any);
            },
            error: (err) => this.errorService.handle(err),
        });
    }

    private setFormFields(fields: Partial<UniFieldLayout>[]) {
        const { readonly, isJournalLines } = this.options?.data || {};

        fields.forEach((field, index) => {
            if (field.Property === 'ProductID') {
                field.Classes = 'full-width';
            }

            field.ReadOnly = readonly || (!isJournalLines && index <= 2);

            if (field.Options?.lookupUrl) {
                const itemTemplate = (item) => {
                    if (field.Options.itemTemplate) {
                        return field.Options.itemTemplate.map((property) => item[property]).join(' - ');
                    } else {
                        return get(item, field.Options.displayProperty);
                    }
                };

                field.Options.template = itemTemplate;

                if (['CustomProductQuality', 'CustomProductAddition', 'CustomProductDetail'].includes(field.Property)) {
                    /*
                     * The datasets for these fields are too big to go into an unfiltered select dropdown.
                     * Switch to autocomplete field type, and add filtering and pagination.
                     * The lookups are cached in itemSourceService, and filtering happens client side.
                     */
                    field.FieldType = FieldType.AUTOCOMPLETE;
                    field.Options = {
                        ...field.Options,
                        getDefaultData: (value: any) => {
                            if (value) {
                                return this.lookup(field.Options.lookupUrl).pipe(
                                    map((items: any[]) => {
                                        return items.filter((item) => item[field.Options.valueProperty] === value);
                                    }),
                                );
                            } else {
                                return of([]);
                            }
                        },
                        search: (query: string) => {
                            return this.lookup(field.Options.lookupUrl).pipe(
                                map((items: any[]) => {
                                    return items
                                        .filter((item) => itemTemplate(item).toLowerCase().includes(query))
                                        .slice(0, 100);
                                }),
                            );
                        },
                    };
                } else if (field.Property === 'ProductID') {
                    /*
                     * We need to do server-side filtering here, since there could be thousands of products.
                     * Switch to autocomplete field type, ignore lookupUrl, and build our own query instead.
                     */
                    field.FieldType = FieldType.AUTOCOMPLETE;
                    field.Options = {
                        ...field.Options,
                        getDefaultData: (value: any) => {
                            if (value) {
                                return this.lookup(`/products?filter=${field.Options.valueProperty} eq ${value}`);
                            } else {
                                return of([]);
                            }
                        },
                        search: (query: string) => {
                            let route = `/products?filter=StatusCode eq 35001`;
                            if (query) {
                                route += ` and contains(Name,'${query}')`;
                            }
                            return this.lookup(route);
                        },
                    };
                } else {
                    field.Options = {
                        ...field.Options,
                        source: () => this.lookup(field.Options.lookupUrl),
                    };
                }
            }
        });

        this.fields$.next(fields);
    }

    checkForSaveKey(event) {
        if (event.key === 's' && (event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            event.stopPropagation();

            const activeElement = document.activeElement as HTMLElement;

            if (activeElement?.blur) {
                activeElement.blur();
            }

            // Wait for change event triggered by bluring an input to be handled before saving
            setTimeout(() => {
                this.save();
                if (activeElement?.focus) {
                    activeElement.focus();
                }
            });
        }
    }

    save() {
        this.onClose.emit(this.itemSource$.getValue());
    }

    private lookup(url: string) {
        if (url.startsWith('https://')) {
            url = url.replace('https://agri.unimicro.no', environment.AGRI_BASE_URL);
            return this.itemSourceService.agriDataLookup(url);
        } else {
            url = `/api/biz/${url}`.replace(/\/+/g, '/');
            return this.http.get<any>(url);
        }
    }
}
