import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';
import { ConfirmActions, UniModalService } from '@uni-framework/uni-modal';
import { AttachmentColImageModalProps } from '@uni-framework/ui/ag-grid/reuseable-columns/attachment-col';
import { ImageModal } from '../modals/ImageModal';
import { UniTableColumn } from '@uni-framework/ui/unitable';
import { lastValueFrom } from 'rxjs';
import { TableDataService } from '@uni-framework/ui/ag-grid/services/data-service';

@Component({
    selector: 'attachments-cell',
    template: `
        <button
            class="attachment-cell-button w-100 rounded unstyled "
            role="button"
            (click)="onClickFn?.()"
            role="button"
        >
            {{ content || '' }}
        </button>
    `,
    styles: `
        :host {
            min-width: 100%;
            min-height: 100%;
            display: block;
            button.attachment-cell-button {
                height: 100%;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsCell {
    @Input() row: any;

    column: UniTableColumn;

    options: AttachmentColImageModalProps;

    content: string | undefined = '';

    onClickFn: any = undefined;

    constructor(
        private readonly modalService: UniModalService,
        private readonly TableService: TableDataService,
    ) {}

    ngOnInit() {
        this.content = this.column.template(this.row);
        this.options ??= {} as AttachmentColImageModalProps;

        if (this.column?.onCellClick !== undefined) {
            this.onClickFn = this.column.onCellClick;
        } else if (Object.keys(this.column.options)?.length) {
            Object.assign(this.options, this.column.options);
            this.onClickFn = this.onClickAttachment;
        }
    }

    async onClickAttachment(event) {
        if (!Object.keys(this.options ?? {})?.length) {
            return;
        }

        let ent = this.options.entity;
        let entID = this.options.entityID;

        if ((!ent || !entID) && typeof this.column.options?.entityResolver === 'function') {
            const resolved = this.options.entityResolver(this.row, this.column);
            if (!ent) {
                ent = resolved.entity;
            }
            if (!entID) {
                entID = resolved.entityID;
            }
        }

        const result = await lastValueFrom(
            this.modalService.open(ImageModal, {
                data: { ...this.options, entityID: entID, entity: ent },
            }).onClose,
        );

        if (result === null) {
            return;
        } else if ((!result?.files?.length && !!this.content) || (!this.content.length && result.files?.length)) {
            this.TableService.refreshData();
        }
    }
}
