import { Component, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DesignSystemModule } from '../design-system/design-system.module';
import { CommonModule } from '@angular/common';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';

@Component({
    selector: 'uni-multi-select',
    templateUrl: './multi-select.html',
    standalone: true,
    styleUrls: ['./multi-select.sass'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UniMultiSelect, multi: true }],
    imports: [CommonModule, FormsModule, UniFrameworkModule],
})
export class UniMultiSelect implements ControlValueAccessor {
    @Input() multiple: boolean;
    @Input() items: any[];
    @Input() placeholder: string;
    @Input() label: string;
    @Input() bindValue: string;
    @Input() searchFn: (item) => {};
    @Input() selected: number | number[];
    @Input() disabled: boolean;
    @Input() appendTo: string;
    @Input() showError: boolean;
    @Input() errorMsg: string;
    @Input() groupByFn: (item) => any;
    @Input() groupValFn: (_: string, items: any[]) => any;
    @Input() icon: string;
    @Input() bindLabel: string;
    @Input() displayFn: (item) => string;

    _updateControlValueAccessor = (value: any) => {};

    constructor(private el: ElementRef) {}

    ngOnInit() {
        if (this.items) {
            const canUseDisplayFn = typeof this.displayFn === 'function';
            this.items.forEach(
                (item) =>
                    (item['_displayValue'] = canUseDisplayFn
                        ? this.displayFn(item)
                        : (item[this.bindLabel] ?? '[ukjent navn]')),
            );
        }
    }

    onChange(value: number[]) {
        this.selected = value;
        this._updateControlValueAccessor(value);
    }
    writeValue(value: number[] | number): void {
        this.selected = value;
    }

    registerOnChange(fn: any): void {
        this._updateControlValueAccessor = fn;
    }

    focus() {
        this.el?.nativeElement?.querySelector('input').focus();
    }

    registerOnTouched(fn: any): void {}

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
