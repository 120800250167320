import { Injectable, signal } from '@angular/core';
import { FinancialReportComment } from '@uni-entities';
import { UniHttp } from '@uni-framework/core/http/http';
import { concat, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, tap, concatMap, catchError, switchMap } from 'rxjs/operators';
import { ErrorService } from '../common/errorService';

export enum CommentType {
    Comment = 1,
    Template = 2,
}

export enum TemplateType {
    Intro = 1,
    Summary = 2,
}

@Injectable({ providedIn: 'root' })
export class FinancialReportCommentService {
    commentAdded$ = new Subject<FinancialReportComment>();
    templates = signal<FinancialReportComment[]>([]);

    constructor(
        protected http: UniHttp,
        private errorService: ErrorService,
    ) {}

    getAll(entity: string, entityID: number, companyKey?: string, allowTags = false) {
        const route = 'financial-report-comments';
        const args = [`filter=entitytype eq '${entity}' and entityid eq ${entityID}`, 'expand=Author'];
        if (allowTags) args.push('allowtags=true');
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`${route}?${args.join('&')}`)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .send({}, null, companyKey ? false : true)
            .pipe(map((res) => res.body || []));
    }

    saveAll(comments: FinancialReportComment[], companyKey?: string): Observable<any> {
        const updateRequests = comments
            .filter((p) => p.ID && !p.Deleted)
            .map((item) => this.put(item.ID, item.Text, item.Title, companyKey));

        const newRequests = comments.filter((p) => !p.ID).map((item) => this.post(item, companyKey));

        const deleteRequests = comments.filter((p) => p.ID && p.Deleted).map((item) => this.delete(item.ID));

        const allRequests = [...updateRequests, ...newRequests, ...deleteRequests];

        if (allRequests.length > 0) {
            return forkJoin(allRequests);
        }

        return of([]);
    }

    delete(id: number) {
        return this.http
            .asDELETE()
            .usingBusinessDomain()
            .withEndPoint(`${FinancialReportComment.RelativeUrl}/${id}`)
            .send({}, undefined, true)
            .pipe(map((res) => res.body));
    }

    put(id: number, message: string, title?: string, companyKey?: string) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`${FinancialReportComment.RelativeUrl}/${id}`)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .withBody({ ID: id, Text: message, Title: title })
            .send({}, undefined, !companyKey)
            .pipe(map((res) => res.body));
    }

    post(comment: FinancialReportComment, companyKey?: string) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${FinancialReportComment.RelativeUrl}`)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .withBody(comment)
            .send({}, null, companyKey ? false : true)
            .pipe(
                map((res) => res.body as FinancialReportComment),
                tap((res) => res && this.commentAdded$.next(res)),
            );
    }

    loadTemplates(): void {
        this.loadAndSetTemplate().subscribe();
    }

    private loadAndSetTemplate() {
        return this.getAll('financialreport', 0, undefined, true).pipe(
            catchError((err) => {
                this.errorService.handle(err);
                return of([]);
            }),
            tap((templates) => {
                if (templates) {
                    this.templates.set(templates.filter((t) => t.CommentType === CommentType.Template));
                }
            }),
        );
    }

    saveTemplate(template: FinancialReportComment) {
        return this.saveAll([template]).pipe(switchMap(() => this.loadAndSetTemplate()));
    }
}
