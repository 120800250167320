import { Injectable } from '@angular/core';
import { ElsaProduct } from '@app/models';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';
import { BankIntegrationAgreement, StatusCodeBankIntegrationAgreement } from '@uni-entities';
import { Observable, map, of } from 'rxjs';
import { BankService } from '../accounting/bankService';
import { ElsaProductService } from '../elsa/elsaProductService';
import { ElsaPurchaseService } from '../elsa/elsaPurchasesService';
import { AuthService } from '@app/authService';
import { UniHttp } from '@uni-framework/core/http';
import { theme, THEMES } from 'src/themes/theme';

@Injectable({ providedIn: 'root' })
export class OnboardingBankService {
    constructor(
        private elsaPurchasesService: ElsaPurchaseService,
        private elsaProductService: ElsaProductService,
        private bankService: BankService,
        private authService: AuthService,
        private uniHttp: UniHttp,
    ) {}

    // TODO: implement this for SB1 in bankid phase 2
    createAgreementBankID(orgNr?: string, companykey?: string) {
        return new Observable((observer) => {
            // remove when implementing
            observer.next(null);
            observer.complete();
            //
        });
    }

    connectBank(
        companyKey: string,
        orgNumber: string,
        bankIdentifier: string,
        hasRecievables?: boolean,
        hasPayments?: boolean,
        hasPreApprovedPayment?: boolean,
        hasStatements?: boolean,
    ) {
        const body = {
            HasRecievables: hasRecievables ? true : null,
            HasPayments: hasPayments ? true : null,
            HasPreApprovedPayment: hasPreApprovedPayment ? true : null,
            HasStatements: hasStatements ? true : null,
            BankIdentifier: bankIdentifier,
        };
        return this.uniHttp
            .asPOST()
            .withHeader('CompanyKey', companyKey)
            .withCredentials()
            .withBody(body)
            .usingInitDomain()
            .withEndPoint(`connect-bank/${orgNumber}`)
            .send({}, null, false)
            .pipe(map((res) => res.body));
    }

    createBankAgreement(
        phone: string,
        companykey: string,
        hasPayments: boolean,
        hasPreApprovedPayment: boolean,
        hasStatements: boolean,
        bankStatementStartDate?: Date,
    ) {
        const productFilter = `contains(tolower(name),'.statements') or contains(tolower(name),'.payments') or name eq 'Autobank'`;

        const globalIdentity =
            this.authService.currentUser?.GlobalIdentity ?? this.authService.getGlobalIdentityFromToken();
        if (!globalIdentity) return of(false);

        return new Observable((observer) => {
            this.elsaProductService.GetAll(productFilter).subscribe(
                (products: ElsaProduct[]) => {
                    if (!products?.length) {
                        // This could be a big error ?? Bank products not present in Elsa
                        console.log('Could not find bank products');
                        observer.error('Could not find bank products');
                        observer.complete();
                        return;
                    }

                    const purchases = [];
                    const statementProduct = hasStatements
                        ? products.find((x) => x.Name?.toLowerCase().includes('.statements'))
                        : null;
                    //const paymentProduct = hasPayments ? products.find(x => x.Name?.toLowerCase().includes('.payments')) : null;
                    //const sb1RgbProduct = hasPayments && hasPreApprovedPayment ? products.find(x => x.Name === 'SB1.RGB') : null;
                    const aritmaAutobankProduct = hasPayments ? products.find((x) => x.Name === 'Autobank') : null;

                    if (statementProduct) {
                        purchases.push({ ID: null, ProductID: statementProduct.ID, GlobalIdentity: globalIdentity });
                    }

                    // if (paymentProduct) {
                    //     purchases.push({ID: null, ProductID: paymentProduct.ID, GlobalIdentity: globalIdentity});
                    // }

                    //until further notice sb1 uses aritma. Aritma has it's own setting for rgb
                    //if (sb1RgbProduct) {
                    //     purchases.push({ID: null, ProductID: sb1RgbProduct.ID, GlobalIdentity: globalIdentity});
                    //}

                    //until sb1 has their own implementation for autobank we use Aritma as broker.
                    //We still need to buy sb1PaymentProduct to keep state, since this is used to onboard services at SB1
                    if (aritmaAutobankProduct) {
                        purchases.push({
                            ID: null,
                            ProductID: aritmaAutobankProduct.ID,
                            GlobalIdentity: globalIdentity,
                        });
                    }

                    if (!purchases.length) {
                        observer.next(null);
                        observer.complete();
                    }

                    this.elsaPurchasesService.massUpdate(purchases, companykey).subscribe(() => {
                        const orderStatements = hasStatements && theme.theme !== THEMES.SR; //for sb1 we do not need both the native bankstatement product and aritma
                        const obs = aritmaAutobankProduct
                            ? this.bankService.createInitialAgreement(
                                  {
                                      ServiceProvider: BankAgreementServiceProvider.ZdataV3,
                                      IsOutgoing: true,
                                      IsBankBalance: orderStatements,
                                      IsBankStatement: orderStatements,
                                      BankApproval: !hasPreApprovedPayment, //BankApproval == true means approve in bank instead of rgb
                                      Phone: phone,
                                  },
                                  companykey,
                              )
                            : of(true);

                        obs.subscribe({
                            next: () => {
                                this.bankService
                                    .GetUntil(
                                        {
                                            relativeURL: '/bank-agreements',
                                            filter: 'filter=StatusCode eq 700005',
                                            interval: 1000,
                                            timeout: 30000, //timeout should be lower in prod, but test env is sometimes a bit slow!!!
                                        },
                                        (agreements: BankIntegrationAgreement[]) => {
                                            return agreements.some(
                                                (x) => x.StatusCode === StatusCodeBankIntegrationAgreement.Active,
                                            );
                                        },
                                    )
                                    .subscribe(
                                        (agreements: BankIntegrationAgreement[]) => {
                                            const bankBalanceAgreement = agreements.find((a) => a.IsBankBalance);
                                            if (bankBalanceAgreement && bankStatementStartDate) {
                                                bankBalanceAgreement.BankStatementStartDate = bankStatementStartDate;
                                                this.bankService
                                                    .updateBankIntegrationAgreement(
                                                        bankBalanceAgreement.ID,
                                                        bankBalanceAgreement,
                                                    )
                                                    .subscribe({
                                                        next: (agreement) => {
                                                            observer.next(agreement);
                                                            observer.complete();
                                                        },
                                                        error: (error) => {
                                                            console.log('Could not update autobank agreement:', error);
                                                            observer.error(error);
                                                            observer.complete();
                                                        },
                                                    });
                                            } else {
                                                observer.next(null);
                                                observer.complete();
                                            }
                                        },
                                        (error) => {
                                            console.log('Timeout: ', error);
                                            observer.next(null); //even though there was a timeout error we still continue.
                                            observer.complete();
                                        },
                                    );
                            },
                            error: (error) => {
                                console.log('Could not create autobank agreement:', error);
                                observer.next(null); // error instead of next?
                                observer.complete();
                            },
                        });
                    });
                },
                (err) => {
                    console.log('Could not load bank products');
                    observer.error(err);
                    observer.complete();
                },
            );
        });
    }
}
