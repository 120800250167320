import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType, UniFieldLayout } from '@uni-framework/ui/uniform';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { BehaviorSubject } from 'rxjs';

export interface TextGenerationSettings {
    AddIntroText: boolean;
    AddExtroText: boolean;
    ShortComments: boolean;
    Language?: TextGenerationLanguage;
    HelpLabel?: string;
    HelpText?: string;
}

export interface TextGenerationLanguage {
    ID: number;
    Name: string;
    Example?: string;
    Temperature?: number;
    IncludeCurrency?: boolean;
    IsDialect?: boolean;
}

export const TextGenerationLanguages = [
    { ID: 1, Name: 'Norsk (bokmål)' },
    { ID: 2, Name: 'Norsk (nynorsk)' },
    { ID: 3, Name: 'Engelsk', IncludeCurrency: true },
    { ID: 5, Name: 'Svensk', IncludeCurrency: true },
    { ID: 6, Name: 'Dansk', IncludeCurrency: true },
    { ID: 7, Name: 'Tysk', IncludeCurrency: true },
    { ID: 8, Name: 'Fransk', IncludeCurrency: true },
    { ID: 9, Name: 'Spansk', IncludeCurrency: true },
    { ID: 10, Name: 'Italiensk', IncludeCurrency: true },
    { ID: 11, Name: 'Polsk', IncludeCurrency: true },
    { ID: 12, Name: 'Kinesisk', IncludeCurrency: true },
    {
        ID: 13,
        Name: "'Ramsalt Nordnorsk'",
        Example: "(eksempel: '*%!# bra resultat' (der banneord er byttet ut med tegn)) og litt humor",
        Temperature: 90,
        IsDialect: true,
    },
];

@Component({
    selector: 'textgeneration-settings-modal',
    template: `
        <section role="dialog" class="uni-modal">
            <header>
                Bruk KI-generert tekstforslag
                <span class="uni-chip info ml-1">Beta</span>
            </header>

            <article>
                <section class="flex-column gap-1 mb-2 ml-1">
                    <a (click)="show = !show">
                        {{ options.data?.HelpLabel || 'Les mer' }}
                        <i class="material-icons" [class.rotate]="show">expand_more</i>
                    </a>

                    <span [innerHTML]="options.data.HelpText" class="expandable-container" [class.expanded]="show">
                        {{ options.data?.HelpText }}
                    </span>
                </section>

                <h3>
                    Utforming
                    <i [uniTooltip]="tooltip" class="material-icons tooltip-color">help_outline</i>
                </h3>

                <uni-form [config]="config$" [fields]="fields$" [model]="model$"> </uni-form>
            </article>

            <footer>
                <button class="secondary" (click)="close(false)">Avbryt</button>
                <button class="c2a" (click)="close(true)">Generer</button>
            </footer>
        </section>
    `,
    styleUrls: ['./textGenerationSettingsModal.sass'],
})
export class TextGenerationSettingsModal implements IUniModal, OnInit {
    @Input() options: IModalOptions = {};
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    config$: BehaviorSubject<any> = new BehaviorSubject({ autofocus: true, showLabelAbove: true });
    fields$: BehaviorSubject<UniFieldLayout[]> = new BehaviorSubject([]);
    model$: BehaviorSubject<TextGenerationSettings> = new BehaviorSubject(null);

    show: boolean = false;
    tooltip: string = 'Innledning og oppsummering kan redigeres under <b>Tekstmaler</b>.';

    ngOnInit() {
        this.fields$.next(<any>this.getFormFields());
        this.model$.next(this.options.data);
    }

    ngOnDestroy() {
        this.fields$.complete();
        this.model$.complete();
    }

    public close(emitValue?: boolean) {
        if (emitValue) {
            const model = this.model$.getValue();
            if (model.Language && !model.Language.ID) {
                const id = <any>model['Language'];
                if (id > 0) {
                    model.Language = TextGenerationLanguages.find((l) => l.ID == id);
                }
            }
            this.onClose.emit(model);
        } else {
            this.onClose.emit();
        }
    }

    getFormFields() {
        return [
            {
                Property: 'AddIntroText',
                FieldType: FieldType.CHECKBOX,
                Label: 'Legg til standard innledning',
            },
            {
                Property: 'AddExtroText',
                FieldType: FieldType.CHECKBOX,
                Label: 'Legg til standard oppsummering',
            },
            {
                Property: 'Language',
                FieldType: FieldType.DROPDOWN,
                Label: 'Språk',
                //Classes: 'full-width',
                Options: {
                    valueProperty: 'ID',
                    displayProperty: 'Name',
                    source: TextGenerationLanguages,
                    hideDeletebutton: true,
                },
            },
        ];
    }
}
