<div class="flex-column h-100 gap-1">
    <section class="template-editor-main custom-scrollbar">
        <section class="mr-1" [attr.aria-busy]="loading">
            <p>Standard tekstmaler:</p>

            @for (template of groupedTemplates().systemTemplates; track template.ID) {
                <div class="template-card bg-gray rounded mb-1 flex align-center gap-1">
                    <div class="icon-circle ml-1">
                        <i class="material-icons-outlined">article</i>
                    </div>
                    <div class="flex-1">
                        <span class="ml-1">{{ template.Title }}</span>
                    </div>
                    <button class="icon-button m-0" (click)="addOrEditTemplate(template, true)">
                        <i class="material-icons-outlined">edit</i>
                    </button>
                </div>
            }
        </section>

        <section class="mr-1" [attr.aria-busy]="loading">
            <p>Dine tekstmaler:</p>

            @for (template of groupedTemplates().customTemplates; track template.ID) {
                <div class="template-card bg-gray rounded mb-1 flex align-center gap-1">
                    <div class="icon-circle custom ml-1">
                        <i class="material-icons-outlined">edit_note</i>
                    </div>
                    <div class="flex-1">
                        <span class="ml-1">{{ template.Title }}</span>
                    </div>
                    <div>
                        <button class="icon-button m-0" (click)="addOrEditTemplate(template, true)">
                            <i class="material-icons-outlined">edit</i>
                        </button>
                        <button class="icon-button m-0" (click)="copyTemplate(template)">
                            <i class="material-icons-outlined">content_copy</i>
                        </button>
                        <button class="icon-button m-0" (click)="deleteTemplate(template)">
                            <uni-icon [icon]="deleteIcon" [svgSize]="24"></uni-icon>
                        </button>
                    </div>
                </div>
            }
        </section>
    </section>

    <section class="new-custom">
        <button class="tertiary c2a" (click)="addOrEditTemplate()">
            <span style="font-size: x-large; font-weight: lighter">+</span>
            <span style="padding-left: 6px">Lag ny tekstmal</span>
        </button>
    </section>
</div>
