<section class="startup-shortcuts flex-column mt-2">
    <button
        class="shortcut unstyled"
        *ngFor="let shortcut of shortCuts"
        [title]="shortcut.label"
        (click)="onShortCutClicked(shortcut)"
    >
        <span class="ellipsis"> {{ shortcut.label }} </span>
    </button>
</section>
