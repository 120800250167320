import { Injectable } from '@angular/core';
import { UniHttp } from '@uni-framework/core/http';
import { map, catchError, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Account, BankAccount } from '@uni-entities';
import { environment } from 'src/environments/environment';
import { THEMES, theme } from 'src/themes/theme';

@Injectable({ providedIn: 'root' })
export class InitService {
    private templates: any[];
    private isDnb = theme.theme === THEMES.EXT02;

    constructor(private uniHttp: UniHttp) {}

    getTemplates() {
        if (this.templates && this.templates.length) {
            return of(this.templates);
        } else {
            return this.uniHttp
                .asGET()
                .usingInitDomain()
                .withEndPoint('template-companies')
                .send()
                .pipe(
                    map((res) => res.body || []),
                    catchError((err) => {
                        console.error(err);
                        return of([]);
                    }),
                    tap((templates) => (this.templates = templates)),
                );
        }
    }

    getCompanyTemplate(isEnk: boolean, includeVat: boolean) {
        return this.getTemplates().pipe(
            map((templates) => {
                return (templates || []).find((template) => {
                    if (template.IsTest || !template.IsGlobalTemplate) {
                        return false;
                    }

                    const name = template.Name || '';

                    if (isEnk && name.includes('MAL AS')) {
                        return false;
                    }

                    if (!isEnk && name.includes('MAL ENK')) {
                        return false;
                    }

                    if (includeVat && name.includes('uten mva')) {
                        return false;
                    }

                    if (!includeVat && name.includes('med mva')) {
                        return false;
                    }

                    if (name.includes('med lønn')) {
                        return false;
                    }

                    return true;
                });
            }),
            catchError(() => of(null)),
        );
    }

    createCompany(body, setPendingStatus = false) {
        let endpoint = 'create-company';

        if (setPendingStatus) {
            endpoint += '?licenseStatus=3';
        }

        return this.uniHttp
            .asPOST()
            .usingInitDomain()
            .withCredentials()
            .withEndPoint(endpoint)
            .withBody(body)
            .send({}, null, false)
            .pipe(map((res) => res.body));
    }

    getCompanies(orgNumber?: string) {
        const orgNumberQuery = orgNumber ? `?organizationNumber=${orgNumber}` : '';
        return this.uniHttp
            .asGET()
            .usingInitDomain()
            .withEndPoint(`companies${orgNumberQuery}`)
            .send()
            .pipe(
                map((res) => res.body),
                catchError((err) => {
                    console.error(err);
                    return of([]);
                }),
            );
    }

    getConfirmationContractType() {
        return this.uniHttp
            .asGET()
            .usingInitDomain()
            .withEndPoint('confirmation/contracttype')
            .send()
            .pipe(
                map((res) => res.body),
                catchError((err) => {
                    console.error(err);
                    return of(null);
                }),
            );
    }

    getBicFromAccountNumber(accountNumber: string) {
        return this.uniHttp
            .asGET()
            .usingInitDomain()
            .withEndPoint('bic-from-bankaccount?accountNumber=' + accountNumber)
            .send()
            .pipe(map((res) => res.body));
    }

    acceptUserAgreement(companyKey: string) {
        if (!companyKey) {
            return throwError('No company key');
        }

        return this.uniHttp
            .asPOST()
            .withHeader('CompanyKey', companyKey)
            .usingBusinessDomain()
            .withEndPoint('users?action=accept-UserLicenseAgreement')
            .send({}, null, false)
            .pipe(map((res) => res.body));
    }

    acceptCustomerAgreement(companyKey: string) {
        if (!companyKey) {
            return throwError('No company key');
        }

        return this.uniHttp
            .asPOST()
            .withHeader('CompanyKey', companyKey)
            .usingBusinessDomain()
            .withEndPoint('users?action=accept-CustomerAgreement')
            .send({}, null, false)
            .pipe(map((res) => res.body));
    }

    getEndUserAgreements() {
        if (this.isDnb) {
            return of([]);
        }

        return this.uniHttp
            .asGET()
            .usingInitDomain()
            .withCredentials()
            .withEndPoint('end-user-agreements')
            .send()
            .pipe(
                map((res) => res.body || []),
                catchError((err) => {
                    console.error(err);
                    return of([]);
                }),
            );
    }

    getExternalBankAccounts(organizationNumber: string) {
        // const mock = [
        //     {
        //         AccountNumber: '11112233333',
        //         Label: 'Bankkonto1234',
        //         BankAccountType: 'company',
        //         Account: {},
        //         BankAccountSettings: {
        //             BalanceAvailable: 55000
        //         },
        //     },
        //     {
        //         AccountNumber: '33332211111',
        //         Label: 'Bankkonto9876',
        //         BankAccountType: 'company',
        //         Account: {},
        //         BankAccountSettings: {
        //             BalanceAvailable: 69000
        //         },
        //     },
        //     {
        //         AccountNumber: '44442211111',
        //         Label: 'Bankkonto4444',
        //         BankAccountType: 'tax',
        //         Account: {},
        //         BankAccountSettings: {
        //             IsTaxAccount: true,
        //             BalanceAvailable: 44000
        //         },
        //     },
        // ];
        // return of(mock);

        return this.uniHttp
            .asGET()
            .usingInitDomain()
            .withCredentials()
            .withEndPoint(`external-bank-accounts/${organizationNumber}`) // ?mock=true
            .send()
            .pipe(
                map((res) => this.mapExternalBankAccounts(res.body || [])),
                catchError((err) => {
                    console.error(err);
                    return of([]);
                }),
            );
    }

    mapExternalBankAccounts(bankAccounts: BankAccount[]) {
        if (!bankAccounts.length) return [];
        return bankAccounts.map((bankAccount) => {
            bankAccount.Account = bankAccount.Account || <Account>{};
            return bankAccount;
        });
    }

    getCurrentUserInfo() {
        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withBaseUrl(environment.authority)
            .withEndPoint('api/user/info')
            .send()
            .pipe(
                map((res) => res.body),
                catchError((err) => {
                    console.error(err);
                    return of(null);
                }),
            );
    }
}
