<section role="dialog" [class]="{ 'uni-modal': true, 'semi-large': hasCommentEditor }">
    <rig-wizard
        #wiz
        [steps]="steps"
        [(activeStepValue)]="activeStepValue"
        (activeStepValueChange)="onActiveStepChange($event)"
        showStepsAsTabs="true"
    >
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <h1 class="wizard-header">Rapport - {{ options.header }}</h1>

        <h2 class="step-header flex align-center gap-1 mt-1">
            {{ getStepLabel(activeStepValue) }}
            <i [uniTooltip]="selectedToolTip.label" class="material-icons tooltip-color" *ngIf="selectedToolTip">
                {{ selectedToolTip.icon }}
            </i>
        </h2>

        <section class="steps" [ngClass]="{ 'comment-steps': activeStepValue == 'comments' }">
            <div [hidden]="activeStepValue != 'page1'">
                <uni-form
                    class="mt-1"
                    [fields]="formPages[0].fields$"
                    [model]="formPages[0].model$"
                    [config]="formConfig$"
                    (changeEvent)="onChangeEvent($event, formPages[0])"
                >
                </uni-form>

                @if (options?.data?.parameters?.length > 0) {
                    <div class="rememberForm">
                        <rig-checkbox [(ngModel)]="rememberSelection" (change)="onRememberChange()">
                            Husk utvalg
                        </rig-checkbox>
                    </div>
                }
            </div>

            <div [hidden]="activeStepValue != 'page2'">
                <uni-form
                    class="mt-1"
                    [fields]="formPages[1]?.fields$"
                    [model]="formPages[1]?.model$"
                    [config]="formConfig$"
                    (changeEvent)="onChangeEvent($event, formPages[1])"
                >
                </uni-form>
            </div>

            <text-templates
                #textTemplates
                [id]="commentConfig?.id"
                [hidden]="activeStepValue != 'templates'"
                [visible]="activeStepValue == 'templates'"
            >
            </text-templates>

            <report-comment-editor
                #commentEditor
                [id]="commentConfig?.id"
                [parameters]="commentConfig?.parameters"
                [hidden]="activeStepValue != 'comments'"
                [visible]="activeStepValue == 'comments'"
                [hasAI]="hasAI"
            >
            </report-comment-editor>
        </section>

        <rig-wizard-footer>
            <button
                class="secondary pull-left"
                (click)="activeStepValue === 'comments' ? cancelCommentStep() : cancel()"
            >
                Avbryt
            </button>

            <combo-button [actions]="actions"></combo-button>
        </rig-wizard-footer>
    </rig-wizard>
</section>
